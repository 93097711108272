<template>
    <div class="calendarContainer">
        <div
            class="dayContainer"
            :class="{
                weekend: isWeekEnd(index, month),
                holiday: isHoliday(index, month),
                approvedDay: isApprovedDay(index, month),
                askedDay: isAskedDay(index, month),
            }"
            v-for="index in daysInMonth(month + 1, year)"
            :key="index"
        >
            {{ index }}
        </div>
    </div>
</template>
<script>
    //const Holidays = require("date-holidays-parser");
    //const hd = new Holidays("ES");
    import { Holidays } from "../../common/constantes.js";

    export default {
        name: "CustomCalendar",
        props: ["month", "dateIntervals", "year"],
        data() {
            return {};
        },
        methods: {
            daysInMonth: function (month, year) {
                return new Date(year, month, 0).getDate();
            },
            isWeekEnd: function (day, month) {
                let date = new Date(this.year, month, day);
                return date.getDay() == 0 || date.getDay() == 6;
            },
            isHoliday: function (day, month) {
                //let date = new Date(this.year, month, day);
                //return hd.isHoliday(date);
                return Holidays[month].includes(day);
            },
            isAskedDay: function (day, month) {
                if (this.isWeekEnd(day, month) || this.isHoliday(day, month)) {
                    return false;
                }
                let date = new Date(this.year, month, day);
                let returnValue = false;
                this.dateIntervals.forEach((interval) => {
                    if (date >= interval.start && date <= interval.end && !interval.approved) {
                        returnValue = true;
                    }
                });
                return returnValue;
            },
            isApprovedDay: function (day, month) {
                if (this.isWeekEnd(day, month) || this.isHoliday(day, month)) {
                    return false;
                }
                let date = new Date(this.year, month, day);
                let returnValue = false;
                this.dateIntervals.forEach((interval) => {
                    if (date >= interval.start && date <= interval.end && interval.approved) {
                        returnValue = true;
                    }
                });
                return returnValue;
            },
        },
        mounted() {},
    };
</script>
<style scoped>
    .calendarContainer {
        display: flex;
    }

    .dayContainer {
        padding: 3px;
    }

    .dayContainer.weekend {
        background-color: #95a5a6 !important;
    }

    .dayContainer.holiday {
        background-color: #e67e22 !important;
    }

    .dayContainer.approvedDay {
        background-color: #3498db;
    }

    .dayContainer.askedDay {
        background-color: #81ecec;
    }
</style>
