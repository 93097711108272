<template>
    <div id="SolicitudVacacionesComponent" v-show="visible">
        <div>
            <div class="botonSolicitar" @click="OpenCloseModalVacaciones(!showModalVacaciones)">
                <i class="fa-solid fa-circle-plus"></i>
                <span> Solicitar</span>
            </div>
            <div>
                <modal-vacaciones :visible="showModalVacaciones" tipoFormulario="Solicitud"
                    :diasPendientes="diasPendientes" :oldPetitions="DatosVacaciones"
                    v-on:visibleChild="OpenCloseModalVacaciones" v-on:nuevaSolicitud="ObtenerDatos"
                    v-on:addNewPetition="addNewPetition" />
                <!-- <ModalVacaciones
                    :visible="showModalVacaciones" 
                    tipoFormulario="Solicitud">
                </ModalVacaciones>  -->
            </div>
            <div>
                <h1 class="cabecera">Vacaciones</h1>
                <hr />
                <div class="divTable">
                    <div class="divTableBody">
                        <div class="divTableRow">
                            <div class="divTableCell divTableCellCenter" v-if="diasPendientes != -1">
                                Días disponibles hasta el 31 de enero:
                                {{ diasPendientes > 0 ? diasPendientes : 0 }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="divTable">
                    <div class="divTableBody">
                        <div class="divTableRow">
                            <div class="divTableCell">
                                <form id="search" class="buscador">
                                    Año
                                    <select @change="ObtenerDatos" v-model="yearSeleccionado">
                                        <option v-for="year in years" :key="year" :value="year">
                                            {{ year }}
                                        </option>
                                    </select>
                                </form>
                            </div>
                            <div class="divTableCell divTableCellRight">
                                <div @click="ObtenerDatos" title="Actualizar datos" class="botonRefresh"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="showGridVacaciones">
                    <table class="customGrid">
                        <thead>
                            <tr>
                                <th style="width: 130px; text-align: center" @click="sort('startDate')">
                                    <SortingTableHeader :activeOrder="activeOrder" :order="order" :column="'startDate'"
                                        :title="'Fecha de inicio'" />
                                </th>
                                <th style="width: 130px; text-align: center" @click="sort('endDate')">
                                    <SortingTableHeader :activeOrder="activeOrder" :order="order" :column="'endDate'"
                                        :title="'Fecha de fin'" />
                                </th>
                                <th @click="sort('comments')">
                                    <SortingTableHeader :activeOrder="activeOrder" :order="order" :column="'comments'"
                                        :title="'Comentarios'" />
                                </th>
                                <th style="width: 15px" @click="sort('totalDays')">
                                    <SortingTableHeader :activeOrder="activeOrder" :order="order" :column="'totalDays'"
                                        :title="'Días'" />
                                </th>
                                <th style="width: 25px" @click="sort('status')">
                                    <SortingTableHeader :activeOrder="activeOrder" :order="order" :column="'status'"
                                        :title="'Estado'" />
                                </th>
                                <th style="width: 15px"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="request in DatosVacaciones" :key="request.id">
                                <td style="text-align: center">
                                    {{ moment(request.startDate).format("DD-MM-YYYY") }}
                                </td>
                                <td style="text-align: center">
                                    {{ moment(request.endDate).format("DD-MM-YYYY") }}
                                </td>
                                <td>{{ request.comments }}</td>
                                <td style="text-align: right">{{ request.totalDays }}</td>
                                <td style="text-align: center">{{ getStatusLabel(request.status) }}</td>
                                <td style="text-align: center; cursor: pointer" title="Cancelar petición"
                                    aria-label="Cancelar petición" @click="confirmBoxQueryId = request.id"
                                    v-if="request.status == 'PENDING' || (request.status == 'ACCEPTED' && !hasStartedPeriod(request.startDate))">
                                    <i class="fa-solid fa-circle-xmark"></i>
                                </td>
                                <td v-else></td>
                                <ConfirmationBox :visible="confirmBoxQueryId == request.id" v-on:accept="
                                    closeConfirmBox();
                                    sendCancellation(request.id);
                                " v-on:cancel="closeConfirmBox" :message="
                                        ' que quieres cancelar las vacaciones del ' +
                                        moment(request.startDate).format('DD-MM-YYYY') +
                                        ' al ' +
                                        moment(request.endDate).format('DD-MM-YYYY')
                                    " />
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ModalVacaciones from "./ModalVacaciones.vue";
import ConfirmationBox from "../ConfirmationBox.vue";
import SortingTableHeader from "../SortingTableHeader.vue";
import { APIs, menuIds } from "../../common/constantes.js";
import { useToast } from "vue-toastification";

import _moment from "moment";

const menuId = menuIds.holidaysRequest;

export default {
    name: "SolicitudVacaciones",
    props: ["visible"],
    components: {
        ModalVacaciones,
        ConfirmationBox,
        SortingTableHeader,
    },
    data() {
        return {
            showGridVacaciones: true,
            searchQueryVacaciones: "",
            DatosVacaciones: [],
            showModalVacaciones: false,
            diasPendientes: -1,
            yearSeleccionado: new Date().getFullYear(),
            confirmBoxQueryId: -1,
            toast: useToast(),
            order: {
                startDate: true,
            },
            activeOrder: "startDate",
        };
    },
    watch: {
        visible(newVal) {
            if (newVal) {
                this.ObtenerDatos();
            }
        },
        loading: function (newLoading) {
            if (this.contentShowed == menuId) {
                this.$isLoading(newLoading);
                this.$emit("resized");
            }
        },
        contentShowed: function (newContent) {
            if (newContent == menuId) {
                this.$isLoading(this.loading);
            }
        },
    },
    computed: {
        years() {
            let anyos = [];
            let currentYear = new Date().getFullYear();
            for (let i = currentYear; i > currentYear - 10; i--) {
                anyos.push(i);
            }
            return anyos;
        },
        authToken: function () {
            return this.$store.getters.getToken;
        },
        user: function () {
            return this.$store.getters.getUser;
        },
        contentShowed: function () {
            return this.$store.getters.getVisibleMenu;
        },
    },
    methods: {
        sort: function (column) {
            if (this.DatosVacaciones.length == 0) {
                return;
            }
            if (this.order[column] == undefined) {
                this.order[column] = false;
            }
            if (column.includes("date")) {
                if (this.order[column]) {
                    this.DatosVacaciones = this.DatosVacaciones.sort((a, b) => _moment(a[column]).unix() - _moment(b[column]).unix());
                } else {
                    this.DatosVacaciones = this.DatosVacaciones.sort((a, b) => _moment(b[column]).unix() - _moment(a[column]).unix());
                }
            } else {
                if (typeof this.DatosVacaciones[0][column] == "string")
                    this.DatosVacaciones = this.DatosVacaciones.sort((a, b) => {
                        return ("" + a[column]).localeCompare(b[column]);
                    });
                else this.DatosVacaciones = this.DatosVacaciones.sort((a, b) => a[column] - b[column]);
                if (!this.order[column]) this.DatosVacaciones = this.DatosVacaciones.reverse();
            }
            this.order[column] = !this.order[column];
            this.activeOrder = column;
        },
        OpenCloseModalVacaciones: function (value) {
            if(this.diasPendientes>0){
                this.showModalVacaciones = value;
            }else{
                this.toast.error("No tienes días para solicitar", {
                            timeout: 3000,
                        });
            }
        },
        ObtenerDatos: function () {
            this.loading = true;
            this._getVacaciones().then(() => {
                this._getDiasPendientes().then(() => {
                    this.loading = false;
                    this.order = {
                        startDate: true,
                    };
                    this.activeOrder = "startDate";
                });
            });
        },
        _getVacaciones: async function () {
            return new Promise((resolve, reject) => {
                this.apiQuery(APIs.getHolidays, {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        Authorization: "Bearer " + this.authToken,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        year: this.yearSeleccionado,
                        idApplicant: this.user,
                        absenceReason: "Holidays", //Por el momento solo vacaciones
                    }),
                })
                    .then((response) => response.json())
                    .then((result) => {
                        this.DatosVacaciones = result;

                        this.DatosVacaciones = this.DatosVacaciones.sort((a, b) => _moment(b.startDate).unix() - _moment(a.startDate).unix());

                        resolve(this.DatosVacaciones);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        _getDiasPendientes: async function () {
            return new Promise((resolve, reject) => {
                this.apiQuery(APIs.getDaysData + "?dni=" + this.user, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        Authorization: "Bearer " + this.authToken,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                })
                    .then(async(response) => response.json())
                    .then((result) => {
                        this.$isLoading(false);

                        this.diasPendientes = result.remaining;
                        resolve(result);
                    })
                    .catch((error) => {
                        this.$isLoading(false);

                        reject(error)});
            });
        },
        getStatusLabel: function (status) {
            switch (status) {
                case "PENDING":
                    return "Pendiente";
                case "ACCEPTED":
                    return "Aprobada";
                case "REJECTED":
                    return "Rechazada";
                case "CANCELED":
                    return "Cancelada";
            }
        },
        moment: function (...args) {
            return _moment(...args);
        },
        closeConfirmBox: function () {
            this.confirmBoxQueryId = -1;
        },
        sendCancellation: async function (id) {
            this.loading = true;

            await this.apiQuery(APIs.cancelAbsence, {
                method: "POST",
                mode: "cors",
                headers: {
                    Authorization: "Bearer " + this.authToken,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: id,
            })
                .then((result) => {
                    if (result.ok && (result.status == 200 || result.status == 202)) {
                        this.toast.success("Se ha cancelado la solicitud.", {
                            timeout: 3000,
                        });
                        this.ObtenerDatos();
                    } else {
                        this.toast.error("Ha ocurrido un error con la cancelación, Error: " + result.status, {
                            timeout: 3000,
                        });
                    }
                })
                .catch(() => {
                    this.toast.error("Ha ocurrido un error inesperado con la cancelación intentalo de nuevo más tarde.", {
                        timeout: 3000,
                    });
                });
            this.loading = false;
        },
        addNewPetition: function (petition) {
            this.DatosVacaciones.push(petition);
            this.ObtenerDatos();
        },
        hasStartedPeriod: function (_start) {
            let start = _moment(_start);
            let now = _moment();

            return now.diff(start) > 0;
        },
    },
    mounted() {
        this.ObtenerDatos();
    },
};
</script>
