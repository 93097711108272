import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import _App from "./App.vue";
import Login from "./views/Login.vue";
import MainApp from "./views/MainApp.vue";
import ChangePassword from "./views/ChangePassword.vue";
import PasswordRecovery from "./views/PasswordRecovery.vue";
//import HtmlEditor from "./components/HtmlEditor.vue";
//import { library } from '@fortawesome/fontawesome-svg-core';
//import { faBars } from '@fortawesome/free-solid-svg-icons';
//import FontAwesomeIcon from '@fortawesome/vue-fontawesome';
import Toast from "vue-toastification";
import loading from "vue3-loading-screen";
import "vue-toastification/dist/index.css";
import { store } from "./store/store";
import { apiQuery } from "./common/apiInterceptor";
//library.add(faBars);

const routes = [
    { path: "/portalempleado", component: MainApp, name: "home" },
    { path: "/portalempleado/login", component: Login, name: "login" },
    {
        path: "/portalempleado/changepassword",
        component: ChangePassword,
        name: "passwordChange",
    },
    {
        path: "/portalempleado/passwordrecovery",
        component: PasswordRecovery,
        name: "passwordRecovery",
    },
    { path: "/", redirect: "/portalempleado" },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

const toastOptions = {
    // You can set your default options here
};

router.beforeEach((to) => {
    const token = store.state.authorizationToken;
    if (token != "") {
        if (store.state.mustChangePassword && to.name != "passwordChange") {
            return "/portalempleado/changepassword";
        } else if (to.name == "login") {
            return "/portalempleado";
        }
    } else if (to.name != "login" && to.name != "passwordRecovery") {
        return "/portalempleado/login";
    }
});

const App = createApp(_App);

App.use(router);

App.use(store);

App.use(Toast, toastOptions);
App.use(loading, {
    bg: "rgba(0, 46, 114, 0.5)",
    slot: `
      <div class="px-5 py-3 bg-gray-800 rounded" 
      style='text-align: center; color: white; 
        font-family: "Lato", "Trebuchet MS", "Lucida Sans Unicode",
        "Lucida Grande", "Lucida Sans", Arial, sans-serif;'
        >
        <h3 class="text-3xl text-white"><i class="fa fa-refresh fa-spin fa-3x"></i><br>Cargando</h3>
      </div>
    `,
});

App.mixin({
    methods: {
        apiQuery: apiQuery,
    },
});

App.mount("#app");
