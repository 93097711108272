<template>
    <div class="modal-mask" v-show="visible">
        <div class="modal-wrapper">
            <div class="modal-container">
                <div class="modal-header">
                    <h1>Certificados</h1>
                </div>
                <form>
                    <div class="modal-body" style="display: block">
                        <div v-if="apiError == '' || apiError == null">
                            <span style="font-size: 18px">En esta sección podrá descargar el certificado de retenciones de los últimos 5 años.</span>
                            <br />
                            <table v-show="loaded == true && downloadingDocument == false && DatosCertificado.length > 0" class="customGrid">
                                <thead>
                                    <tr>
                                        <th>Año</th>
                                        <th>Nombre documento</th>
                                        <th>Descarga</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="datum in DatosCertificado" :key="datum.objId">
                                        <td>{{ datum.anyo }}</td>
                                        <td>
                                            <a v-on:click="DescargaCertificado(datum)">{{ datum.nombreDocumento }}</a>
                                        </td>
                                        <td>
                                            <a v-on:click="DescargaCertificado(datum)" aria-label="Descargar PDF"
                                                ><i class="fa-regular fa-file-pdf fa-2x" style="color: red" title="Descargar PDF"></i
                                            ></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <h3 v-show="loaded == true && DatosCertificado.length == 0">No hay certificados para mostrar</h3>
                            <h3 v-show="loaded == false"><i class="fas fa-spinner fa-spin"></i> Cargando certificados...</h3>
                            <h3 v-show="downloadingDocument == true">
                                <i class="fas fa-spinner fa-spin"></i>
                                Descargando documento...
                            </h3>
                        </div>
                        <div v-if="apiError != '' && apiError != null">
                            <h3 style="color: red">
                                <i class="fa-solid fa-triangle-exclamation"></i>
                                {{ apiError }}
                            </h3>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="modal-default-button" @click.prevent="close">Cerrar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import Constantes from "../common/constantes.js";
    const { APIs } = Constantes;

    export default {
        name: "ModalCertificados",
        components: {
            // CustomGrid: () => import("./CustomGrid.vue"),
        },
        props: ["visible"],
        data() {
            return {
                DatosCertificado: [],
                loaded: false,
                downloadingDocument: false,
                certificadoSeleccionado: {},
                apiError: "",
            };
        },
        methods: {
            close() {
                this.$emit("visibleChild", !this.visible);
            },
            ObtenerDatos() {
                const self = this;
                this.DatosCertificado = [];
                this.GetCertificados()
                    .then((datos) => {
                        datos
                            .sort((a, b) => {
                                return a.anyo - b.anyo;
                            })
                            .forEach((element) => {
                                self.DatosCertificado.push({
                                    anyo: element.anyo,
                                    dni: element.dni,
                                    fechaDocumento: element.fechaDocumento,
                                    nombreDocumento: "Certificado_" + element.anyo + ".pdf",
                                    objId: element.objId,
                                    url: APIs.DescargaDocumentos + element.objId,
                                });
                                self.loaded = true;
                            });
                    })
                    .catch(() => (this.apiError = "Ha ocurrido un error, intentalo de nuevo más tarde."));
            },
            GetCertificados() {
                this.apiError = "";
                this.loaded = false;
                return new Promise((resolve, reject) => {
                    this.apiQuery(APIs.certificados + this.user, {
                        method: "GET",
                        mode: "cors",
                        headers: {
                            Authorization: "Bearer " + this.authToken,
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        },
                    })
                        .then(async (result) => {
                            if (result.status === 404) {
                                const reader = await result.text();
                                reject(JSON.parse(reader).message);
                            } else {
                                const reader = await result.text();
                                resolve(JSON.parse(reader));
                            }
                        })
                        .catch((error) => {
                            console.error("error:", error);
                            this.apiError = "Ha ocurrido un error, intentalo de nuevo más tarde.";
                            reject(error);
                        });
                });
            },
            DescargaCertificado(data) {
                this.apiError = "";
                if (this.downloadingDocument || !this.loaded) {
                    return;
                }
                this.downloadingDocument = true;
                const self = this;
                self.certificadoSeleccionado = data;
                this.apiQuery(data.url, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        Authorization: "Bearer " + this.authToken,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                })
                    .then((r) => r.blob())
                    .then(self.showFile)
                    .catch((error) => {
                        this.apiError = "Ha ocurrido un error, intentalo de nuevo más tarde.";
                        this.downloadingDocument = false;
                        console.error("error:", error);
                    });
            },
            showFile(blob) {
                // It is necessary to create a new blob object with mime-type explicitly set
                // otherwise only Chrome works like it should
                var newBlob = new Blob([blob], { type: "application/pdf" });

                // IE doesn't allow using a blob object directly as link href
                // instead it is necessary to use msSaveOrOpenBlob
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(newBlob);
                    this.downloadingDocument = false;
                    return;
                }

                // For other browsers:
                // Create a link pointing to the ObjectURL containing the blob.
                const data = window.URL.createObjectURL(newBlob);
                var link = document.createElement("a");
                link.target = "_blank";
                link.href = data;
                link.download = "Certificado_" + this.certificadoSeleccionado.anyo + ".pdf";
                link.click();
                setTimeout(() => {
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    window.URL.revokeObjectURL(data);
                }, 100);

                this.downloadingDocument = false;
            },
        },
        computed: {
            user() {
                return this.$store.getters.getUser;
            },
            authToken: function () {
                return this.$store.getters.getToken;
            },
        },
        watch: {
            visible(newVal) {
                if (newVal) {
                    this.ObtenerDatos();
                }
            },
        },
    };
</script>
