<template>
    <div>
        <div class="cardGroup">
            <div
                v-for="card in cards"
                :key="card.idMenu"
                class="card small"
                :style="{
                    'background-image': `url('${card.image ? card.image : noImage}')`,
                }"
                @click="setNewMenu(card.idMenu)"
            >
            <!--                v-show="card.idMenu!=4 || this.$store.getters.getBtrlList?.includes('L0') ||this.$store.getters.getBtrlList?.includes('E0')"
-->
                <div class="cardBody small">
                    <h2>{{ card.titulo }}</h2>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { menus, noImage } from "../common/constantes.js";
    //import moment from "moment";

    export default {
        name: "Wellnes Area",
        data() {
            return {
                noImage: noImage,
            };
        },
        methods: {
            setNewMenu: function (menuId) {
                let menuObject = menus.find((e) => e.idMenu == this.contentShowed)?.children?.find((e) => e.idMenu == menuId);
                if (menuObject.type == "menu" || menuObject.type == "submenu") this.$store.commit("setVisibleMenu", menuId);
                else if (menuObject.type == "logout") {
                    this.$store.commit("logout");
                    window.location.reload();
                } else if (menuObject.type == "changePassword") this.$router.push({ name: "passwordChange" });
                else if (menuObject.type == "href") window.open(menuObject.url, "_blank");
            },
        },
        computed: {
            contentShowed: function () {
                return this.$store.getters.getVisibleMenu;
            },
            authToken: function () {
                return this.$store.getters.getToken;
            },
            codHandicapped: function () {
                return this.$store.getters.getUserData.codHandicapped;
            },
            cards: function () {
                if (typeof this.contentShowed === "string" && this.contentShowed.includes("bigmenu")){
                    return menus.find((e) => e.idMenu == this.contentShowed)?.children;
                   /*
                    var filteredAllMenu;
                    filteredAllMenu = // allMenus.filter((menu) => menu.idMenu != 4 || this.$store.getters.getBtrlList?.includes('L0') || this.$store.getters.getBtrlList?.includes('E0'));
                    return filteredAllMenu;
                    */
                }else{
                    return [];
                }
            },
        },
        watch: {
            cards: function () {
                this.$emit("resized");
            },
        },
    };
</script>
<style scoped>
    /** Cards con texto en imagen */

    .card.small {
        height: 300px;
        display: inline-block;
        position: relative;
        transition: all 0.3s ease;
        -webkit-box-shadow: 5px 5px 12px -1px gray;
        box-shadow: 5px 5px 12px -1px gray;
        margin-bottom: 24px;
        cursor: pointer;

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    /* Small Screen */
    @media screen and (max-width: 788px) {
        .card.small {
            width: 100%;
            margin-right: 0;
        }
    }
    /* Big Screen */
    @media screen and (min-width: 788px) {
        .card.small {
            width: 350px;
            margin-right: 24px;
        }
    }

    .cardImage.small {
        width: 100%;
        height: 100%;
        position: absolute;
    }

    .cardBody.small {
        width: 55%;
        height: 45%;
        right: 9%;
        top: 35%;
        background-color: #f5f8fa;
        padding: 10px;
        position: absolute;
        overflow: hidden;
        -webkit-box-shadow: 5px 5px 12px -1px gray;
        box-shadow: 5px 5px 12px -1px gray;
    }

    /** Cards estiradas */
    .card.estirada {
        width: 350px;
        height: 550px;
        display: inline-block;
        position: relative;
        transition: all 0.3s ease;
        -webkit-box-shadow: 5px 5px 12px -1px gray;
        box-shadow: 5px 5px 12px -1px gray;
        margin-right: 5%;
        margin-bottom: 24px;
        cursor: pointer;
        vertical-align: top;
    }

    .cardImage.estirada {
        width: 100%;
        height: 300px;
    }

    .cardBody.estirada {
        width: 100%;
        padding: 10px;
    }

    /** Común para ambos */

    .card:hover {
        -webkit-box-shadow: 5px 5px 12px 2px rgb(100, 100, 100);
        box-shadow: 5px 5px 12px 2px rgb(100, 100, 100);
    }

    .cardBody > p {
        color: gray;
    }

    .cardBody > h2 {
        color: #002e72;
    }

    .cardGroup {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        margin-top: 15px;
        padding-right: auto;
        padding-left: auto;
    }

    .pointer {
        cursor: pointer;
    }

    .content {
        margin-top: 15px;
    }

    .breadCrumb {
        display: flex;
        flex-wrap: wrap;
    }

    .crumb {
        display: flex;
        flex-wrap: nowrap;
        cursor: pointer;
        transition: all 0.3s ease;
        color: #3498db;
        font-weight: normal;
    }

    .crumb:hover {
        color: #2772a3;
        text-decoration: underline;
    }

    .crumbContainers {
        display: flex;
        flex-wrap: nowrap;
    }

    .loadingCard {
        animation: loading 1s infinite ease;
        background-color: #fff;
        background-image: linear-gradient(90deg, #f3f3f3, #eaeaea, #f3f3f3, #eaeaea);
        background-size: 300% 100%;
        width: 350px;
        height: 300px;
        margin-right: 24px;
        margin-bottom: 24px;
        -webkit-box-shadow: 5px 5px 12px -1px #bababa;
        box-shadow: 5px 5px 12px -1px #bababa;
        cursor: pointer;
    }

    .newsGroup {
        display: grid;
        column-gap: 15px;
        width: 100%;
        margin-top: 15px;
        padding-right: auto;
        padding-left: auto;
    }

    .newsCard {
        width: 100%;
        height: 300px;
        -webkit-box-shadow: 5px 5px 12px -1px gray;
        box-shadow: 5px 5px 12px -1px gray;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin-bottom: 24px;
        margin-right: 24px;
        cursor: pointer;
        display: inline-flex;
    }

    /* Small Screen */
    @media screen and (max-width: 788px) {
        .newsGroup {
            grid-template-columns: auto;
        }
    }
    /* Big Screen */
    @media screen and (min-width: 788px) {
        .newsGroup {
            grid-template-columns: 50% 50%;
        }
    }

    .newsHeader {
        flex: 0 1 auto;
    }

    .newsDate {
        background-color: #f5f8fa;
        padding: 7px;
        float: right;
    }

    .newsTitle {
        background-color: #f5f8fa;
        padding: 7px;
        font-weight: bolder;
        font-size: 20px;
    }

    .newsDescription {
        background-color: #f5f8fa;
        padding: 7px;
        width: calc(95% - 14px);
        overflow: hidden;
        flex: 1 1 auto;
    }

    .newsContent {
        background-color: #f5f8fa;
        width: 75%;
        height: 100%;
        display: flex;
        flex-flow: column;
    }

    .newsImage {
        width: 25%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    @keyframes loading {
        0% {
            background-position: 0 0;
        }
        100% {
            background-position: 100% 0;
        }
    }

    .headImage {
        width: calc(100% + 40px);
        height: 150px;
        margin-left: -20px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
    }

    .headTitle {
        background-color: #f5f8fa;
        position: absolute;
        top: 20%;
        left: 2%;
        padding: 5px;
    }
</style>
