<template>
    <div class="modal-mask" v-show="visible">
        <div class="modal-wrapper">
            <div class="modal-container">
                <div class="modal-header">
                    <h2>Confirmación</h2>
                </div>
                <div class="modal-body">
                    <h4>¿Estás seguro que deseas rechazar estás solicitudes?</h4>

                    <h3>Comentario:</h3>
                    <textarea v-model="comment"></textarea>
                    <br />
                    *Nota: el comentario será enviado a todas las solicitudes
                    <br /><br />
                    <button class="modal-button accept" @click.prevent="accept">Sí, rechazar</button>
                    <button class="modal-button cancel" @click.prevent="cancel">No, cancelar</button>
                </div>
            </div>
            <div class="modal-body" v-show="totalToReject != -1">
                <h4>Aprobando solicitudes...</h4>
                {{ rejected }} / {{ totalToReject }}
            </div>
        </div>
    </div>
</template>
<script>
    import { useToast } from "vue-toastification";

    export default {
        name: "Confirmation Box",
        props: ["visible", "totalToReject", "rejected"],
        data() {
            return {
                comment: "",
                toast: useToast(),
            };
        },
        methods: {
            accept: function () {
                if (this.comment == "") {
                    this.toast.error("Debes añadir un motivo al rechazar la solicitud.", {
                        timeout: 3000,
                    });
                } else {
                    this.$emit("accept", this.comment);
                    this.comment = "";
                }
            },
            cancel: function () {
                this.comment = "";
                this.$emit("cancel");
            },
        },
    };
</script>
<style scoped>
    h4 {
        font-size: 25px;
    }

    .modal-wrapper {
        color: black;
        text-align: center;
    }

    .modal-container {
        width: 60%;
        height: 80%;
        min-height: 360px;
        min-width: 300px;
        top: 5%;
        padding: 20px;
        left: calc(50% - max(160px, 60%/2));
    }

    .accept {
        background-color: #27ae60;
        border-color: #27ae60;
        transition: all 0.3s;
    }

    .accept:hover {
        color: #ecf0f1;
    }

    .cancel {
        background-color: #c0392b;
        border-color: #c0392b;
        transition: all 0.3s;
    }

    .cancel:hover {
        color: #ecf0f1;
    }
</style>
