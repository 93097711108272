<template>
    <div class="dateHeader">
        <div style="display: flex; align-items: center">
            <span>{{ title }}</span>
        </div>
        <div style="margin-left: auto; margin-right: 0">
            <div>
                <i
                    class="fa-solid fa-arrow-up fa-xs"
                    :class="{
                        normalArrow: !(activeOrder == column && order[column]),
                        activeArrow: activeOrder == column && order[column],
                    }"
                ></i>
            </div>
            <div>
                <i
                    class="fa-solid fa-arrow-down fa-xs"
                    :class="{
                        normalArrow: !(activeOrder == column && !order[column]),
                        activeArrow: activeOrder == column && !order[column],
                    }"
                ></i>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "Sorting Table Header",
        props: ["activeOrder", "order", "column", "title"],
        data() {
            return {};
        },
    };
</script>
<style scope>
    .normalArrow {
        color: rgba(255, 255, 255, 0.219);
    }

    .activeArrow {
        color: white;
    }

    .dateHeader {
        display: flex;
        cursor: pointer;
    }
</style>
