<template>
    <div v-show="visible">
        Año
        <select v-model="year" @change="getData()">
            <option v-for="year in years" :key="year" :value="year">
                {{ year }}
            </option>
        </select>
        <button class="modal-button" @click="exportToExcel">Exportar</button>

        <span class="spanText">Todos los empleados</span>
        <Input id="check" type="checkbox" class="checkboxImportant" v-model="checkedAllUsers" :checked="checkedAllUsers"
            @change="showAllContent()" @blur="reloadSizeName()" />
        <div class="legendContainer">
            <div class="legendTitle">Leyenda:</div>
            <div class="legendData">
                <div class="legendSquare" style="background-color: #e67e22"></div>
                <div class="legendText">Festivo</div>
            </div>
            <div class="legendData">
                <div class="legendSquare" style="background-color: #95a5a6"></div>
                <div class="legendText">Fin de semana</div>
            </div>
            <div class="legendData">
                <div class="legendSquare" style="background-color: #81ecec"></div>
                <div class="legendText">Día solicitado</div>
            </div>
            <div class="legendData">
                <div class="legendSquare" style="background-color: #3498db"></div>
                <div class="legendText">Día aprobado</div>
            </div>
        </div>
        <div class="container">
            <div class="tableWrapper">
                <table class="customGrid" id="mainTable" columnsFrozen="2">
                    <thead>
                        <tr>
                            <th id="fixedCols" class="fixedCols" style=" height: 3em" fixed>Empleado</th>
                            <th id="fixedCols2" class="fixedCols2" :style="secondLeftFixed()" frozen>Días pendientes
                            </th>
                            <th id="fixedCols3" class="fixedCols3" :style="thirdLeftFixed()">Días utilizados</th>
                            <th class="noPadding" v-for="i in 12" :key="i">
                                {{ getMonthLabel(i - 1) }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="employee in data" :key="employee.id" v-show="checkedAllUsers || employee.absences">
                            <td class="fixedCols" style="padding: 0;" fixed>
                                <div>
                                    <div class="employeeName" :title="employee.name"
                                        style="padding-left: 6px; padding-right: 10px;">
                                        <span> {{ employee.name ? employee.name : "Nombre no encontrado" }}</span>
                                    </div>
                                    <div class="periodName" v-for="periodName in Object.keys(employee.daysPeriods)"
                                        :key="periodName">
                                        {{ getPeriodLabel(periodName)?getPeriodLabel(periodName): "Vacaciones" }}
                                    </div>
                                </div>
                            </td>
                            <td id="daysPendingNumber" style="text-align: center" class="fixedCols2"
                                :style="secondLeftFixed()">{{
                                    employee.daysLeft ? employee.daysLeft : 0
                                }}</td>
                            <td id="daysUsedNumber" style="text-align: center" class="fixedCols3"
                                :style="thirdLeftFixed()">{{ employee.usedDays ? employee.usedDays : 0 }}</td>
                            <td v-for="i in 12" :key="i" class="noPadding">
                                <div style="padding-top: 32px">
                                    <CustomCalendar v-for="period in employee.daysPeriods" :key="period" :month="i - 1"
                                        :year="year" :dateIntervals="period" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import CustomCalendar from "./CustomCalendar.vue";
import * as XLSX from "xlsx-js-style";
import { Holidays, APIs } from "../../common/constantes.js";
import moment from "moment";
import { nextTick } from 'vue'


export default {
    name: "GeneralView",
    components: {
        CustomCalendar,
    },
    props: ["visible"],
    data() {
        return {
            year: new Date().getFullYear(),
            years: [],
            testModel: "test",
            leftSecond: 0,
            leftThird: 0,
            checkedAllUsers: true,

            data: [
                /** Data Sample
                 * {
                    id: 0,
                    name: "Pepe",
                    daysPeriods: {
                        Vacaciones: [
                            {
                                start: new Date(2022, 0, 15),
                                end: new Date(2022, 0, 20),
                                approved: false,
                            },
                            {
                                start: new Date(2022, 1, 18),
                                end: new Date(2022, 1, 24),
                                approved: true,
                            },
                        ],
                    },
                    usedDays: 10,
                    daysLeft: 11,
                }*/
            ],
        };
    },
    watch: {
        visible: function (newVisible) {
            if (newVisible) {
                this.getData();
            }
        },
    },
    methods: {
        reloadSizeName() {
            let elem = document.getElementById("fixedCols");
            elem.style.left = "0px";
            let rect = elem.getBoundingClientRect();
            this.leftSecond = rect.width - 1;

            let elemPendingDays = document.getElementById("fixedCols2");
            let elemUsedDays = document.getElementById("fixedCols3");

            let daysPendingNumber = document.getElementById("daysPendingNumber")
            let daysUsedNumber = document.getElementById("daysUsedNumber");
            daysPendingNumber.style.leftSecond = this.leftSecond + "px";
            daysUsedNumber.style.leftThird = (this.leftSecond + this.leftThird) + "px";

            elemPendingDays.style.leftSecond = rect.right + "px";
            elemUsedDays.style.leftThird = (this.leftSecond + this.leftThird) + "px";
        },

        exportToExcel: function () {
            const wb = XLSX.utils.book_new();

            let monthMerges = [];
            let colWidth = [{ width: 50 }];
            let monthMergeCounter = 1;
            let setBoldLeftBorder = [];
            let monthRows = [
                {
                    v: "Año " + this.year,
                    t: "s",
                },
            ];
            for (let i = 0; i < 12; i++) {
                monthRows[monthMergeCounter] = {
                    v: this.getMonthLabel(i),
                    t: "s",
                    s: {
                        font: { bold: true },
                        alignment: { horizontal: "center" },
                        border: {
                            left: { style: "thick", color: "2c3e50" },
                        },
                    },
                };
                setBoldLeftBorder.push(monthMergeCounter);

                let daysInMonth = this.daysInMonth(i + 1, this.year);
                daysInMonth -= 1;
                monthMerges.push({
                    s: { c: monthMergeCounter, r: 0 },
                    e: { c: monthMergeCounter + daysInMonth, r: 0 },
                });

                for (let j = 0; j <= daysInMonth; j++) {
                    colWidth[monthMergeCounter + j] = { width: 3 };
                }

                monthMergeCounter += daysInMonth + 1;
            }

            let employeeRows = [];
            this.data.forEach((employee) => {
                employeeRows.push([
                    {
                        v: employee.name,
                        t: "s",
                        s: { fill: { fgColor: { rgb: "74B9FF" } } },
                    },
                ]);
                Object.keys(employee.daysPeriods).forEach((key) => {
                    const period = employee.daysPeriods[key];
                    const periodRow = [
                        {
                            v: key,
                            t: "s",
                        },
                    ];

                    const isWeekEnd = (day, month) => {
                        let date = new Date(this.year, month, day);
                        return date.getDay() == 0 || date.getDay() == 6;
                    };
                    const isHoliday = (day, month) => {
                        return Holidays[month].includes(day);
                    };
                    const isAskedDay = (day, month) => {
                        let date = new Date(this.year, month, day);
                        let returnValue = false;
                        period.forEach((interval) => {
                            if (date >= interval.start && date <= interval.end && !interval.approved) {
                                returnValue = true;
                            }
                        });
                        return returnValue;
                    };
                    const isApprovedDay = (day, month) => {
                        let date = new Date(this.year, month, day);
                        let returnValue = false;
                        period.forEach((interval) => {
                            if (date >= interval.start && date <= interval.end && interval.approved) {
                                returnValue = true;
                            }
                        });
                        return returnValue;
                    };

                    for (let month = 0; month < 12; month++) {
                        for (let day = 1; day <= this.daysInMonth(month + 1, this.year); day++) {
                            let style = {};
                            if (isWeekEnd(day, month)) {
                                style = {
                                    fill: {
                                        fgColor: { rgb: "95a5a6'" },
                                    },
                                };
                            } else if (isHoliday(day, month)) {
                                style = {
                                    fill: { fgColor: { rgb: "e67e22" } },
                                };
                            } else if (isAskedDay(day, month)) {
                                style = {
                                    fill: { fgColor: { rgb: "81ecec" } },
                                };
                            } else if (isApprovedDay(day, month)) {
                                style = {
                                    fill: { fgColor: { rgb: "3498db" } },
                                };
                            }

                            periodRow.push({
                                v: day,
                                t: "s",
                                s: style,
                            });
                        }
                    }

                    employeeRows.push(periodRow);
                });
            });

            employeeRows.forEach((row) => {
                setBoldLeftBorder.forEach((key) => {
                    if (row[key]) {
                        row[key].s["border"] = {
                            left: { style: "thick", color: "2c3e50" },
                        };
                    } else {
                        row[key] = {
                            v: "",
                            t: "s",
                            s: {
                                border: {
                                    left: {
                                        style: "thick",
                                        color: "2c3e50",
                                    },
                                },
                            },
                        };
                    }
                });
            });

            // STEP 3: Create worksheet with rows; Add worksheet to workbook
            const allRows = [monthRows];
            employeeRows.forEach((row) => allRows.push(row));
            const ws = XLSX.utils.aoa_to_sheet(allRows);
            ws["!cols"] = colWidth;
            ws["!merges"] = monthMerges;

            XLSX.utils.book_append_sheet(wb, ws, "readme demo");

            // STEP 4: Write Excel file to browser
            XLSX.writeFile(wb, "Report-Vacaciones-" + this.year + ".xlsx");
        },
        getMonthLabel: function (month) {
            switch (month) {
                case 0:
                    return "Enero";
                case 1:
                    return "Febrero";
                case 2:
                    return "Marzo";
                case 3:
                    return "Abril";
                case 4:
                    return "Mayo";
                case 5:
                    return "Junio";
                case 6:
                    return "Julio";
                case 7:
                    return "Agosto";
                case 8:
                    return "Septiembre";
                case 9:
                    return "Octubre";
                case 10:
                    return "Noviembre";
                case 11:
                    return "Diciembre";
            }
            return "";
        },
        daysInMonth: function (month, year) {
            return new Date(year, month, 0).getDate();
        },
        getData: function async() {
            this.apiQuery(APIs.getAllRepresentative, {
                method: "POST",
                mode: "cors",
                headers: {
                    Authorization: "Bearer " + this.authToken,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    dnirepresentative: this.user,
                    year: this.year,
                }),
            })
                .then((response) => response.json())
                .then((result) => {
                    this.data = [];
                    result.forEach((users) => {
                        if (users.applicantRepresentantiveAbsencesResponseList.length == 0) {

                            this.getDiasPendientes(users.userDNI).then((days) => {
                                if (days.ok) {
                                    let row = this.data.find((row) => row.name == users.userName);
                                    row.usedDays = days.taken;
                                    row.daysLeft = days.remaining;
                                }

                            });
                            this.data.push({
                                name: users.userName,
                                usedDays: "0",
                                daysLeft: "0",
                                absences: false,
                                daysPeriods: {
                                    Vacaciones: [
                                        {
                                            start: new Date(),
                                            end: new Date(),
                                        },
                                        {
                                            start: new Date(),
                                            end: new Date(),
                                        },
                                    ],
                                },
                            });

                        } else {
                            users.applicantRepresentantiveAbsencesResponseList.forEach((period) => {
                                let haveAbsence = false;

                                if (period.status == "PENDING" || period.status == "ACCEPTED") {
                                    haveAbsence = true;
                                    if (!this.data.find((row) => row.name == period.userName)) {
                                        this.getDiasPendientes(period.idApplicant).then((days) => {
                                            let row = this.data.find((row) => row.name == period.userName);
                                            row.usedDays = days.taken;
                                            row.daysLeft = days.remaining;
                                        });
                                        this.data.push({
                                            id: period.id,
                                            name: period.userName,
                                            daysPeriods: {},
                                            absences: true,
                                            usedDays: "",
                                            daysLeft: "",
                                        });

                                    }else{
                                        let row = this.data.find((row) => row.name == period.userName);
                                            row.absences = true;
                                    }
                
                                    let applicant = this.data.find((row) => row.name == period.userName);
                                    applicant.absences = true;

                                    if (!applicant?.daysPeriods[period.absenceReason]) {
                                        applicant.daysPeriods[period.absenceReason] = [];

                                    }

                                    let startDate = moment(period.startDate);
                                    let endDate = moment(period.endDate);

                                    applicant.daysPeriods[period.absenceReason].push({
                                        start: new Date(startDate.year(), startDate.month(), startDate.date()),
                                        end: new Date(endDate.year(), endDate.month(), endDate.date()),
                                        approved: period.status == "ACCEPTED",
                                    });
                                } else {
                                    if (!this.data.find((row) => row.name == period.userName)) {
                                        this.getDiasPendientes(period.idApplicant).then((days) => {
                                            let row = this.data.find((row) => row.name == period.userName);
                                            row.usedDays = days.taken;
                                            row.daysLeft = days.remaining;
                                        });
                                        this.data.push({
                                            absences: haveAbsence,
                                            id: period.id,
                                            name: period.userName,
                                            daysPeriods: {},
                                        });
                                    }

                                    let applicant = this.data.find((row) => row.name == period.userName);

                                    if (!applicant?.daysPeriods[period.absenceReason]) {
                                        applicant.daysPeriods[period.absenceReason] = [];
                                    }
                                }
                            });
                        }
                    });
                });
        },
        async showAllContent() {

            var check = document.getElementById("check");
            if (check.checked) {
                this.checkedAllUsers = true;
            } else {
                this.checkedAllUsers = false;
            }

            await nextTick();
            this.reloadSizeName();


        },
        getDiasPendientes: async function (dni) {
            return new Promise((resolve, reject) => {
                this.apiQuery(APIs.getDaysData + "?dni=" + dni, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        Authorization: "Bearer " + this.authToken,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                })
                    .then((response) => response.json())
                    .then((result) => {
                        resolve(result);
                    })
                    .catch(() => {
                        reject(null);
                    });
            });
        },
        getPeriodLabel: function (name) {
            switch (name) {
                case "Holidays":
                    return "Vacaciones";
            }
        },
        secondLeftFixed: function () {
            //cogemos el width del elemento anterior al ser variable segun el nombre y para fixearlo donde este termina
            var elem = document.getElementById("fixedCols");
            if (elem) {
                var rect = elem.getBoundingClientRect();
                this.leftSecond = rect.width - 1;
                return { '--leftSecond': (rect.width - 1) + 'px' };

            }

            return { '--leftSecond': '' };

        },
        thirdLeftFixed: function () {
            var elem = document.getElementById("fixedCols2");
            if (elem) {
                var rect = elem.getBoundingClientRect();
                this.leftThird = rect.width;

                return { '--leftThird': (this.leftSecond + rect.width) + 'px' };

            }

            return { '--leftThird': '' };

        },
    },
    mounted() {
        let year = new Date().getFullYear();
        for (let i = 0; i < 5; i++) {
            this.years.push(year - i);
        }
        this.getData();

    },
    computed: {
        authToken: function () {
            return this.$store.getters.getToken;
        },
        user: function () {
            return this.$store.getters.getUser;
        },


    },


};

</script>
<style scoped>
.fixedCols {
    position: sticky;
    left: -1px;
    z-index: 10;
    margin-block-end: 10px;
    border-color: white;
    border-right-style: inset;
}

.fixedCols2 {
    position: sticky;
    left: var(--leftSecond);
    z-index: 10;
    margin-block-end: 10px;
    border-color: white;
    border-right-style: inset;
}

.fixedCols3 {
    position: sticky;
    left: var(--leftThird);
    z-index: 10;
    margin-block-end: 10px;
    border-color: white;
    border-right-style: inset;
}

.spanText {
    margin-left: 1em;

}

.checkboxImportant {
    margin: 0 auto;
    position: absolute;
    margin-left: 0.5em;
    margin-top: 6px;

    padding: 6px;
    width: 2em;
    height: 2em;
}

.employeeName {
    background-color: #74b9ff;
    padding-top: 6px;
    padding-bottom: 6px;
}

.periodName {
    padding: 3px 0px 3px 12px;
    width: 10em;
    background-color: #ecf0f1;
}

.noPadding {
    padding-top: 0;
    padding-bottom: 0;
}

.tableWrapper {
    overflow-x: scroll;
    margin-left: 0.5em;
    margin-right: 0.5em;
}

.container {
    border: 1px solid #002e72;
    border-radius: 5px;
    padding: 1px;
    margin-top: 25px;
}



th,
td {
    white-space: nowrap;
    height: 50px;
}

.customGrid {
    border: 0;
}

.legendContainer {
    display: flex;
    vertical-align: middle;
    height: 10px;
    margin-top: 15px;
}

.legendTitle {
    font-weight: bolder;
    margin-right: 20px;
    vertical-align: middle;
    height: 100%;
}

.legendData {
    margin-left: 24px;
    height: 100%;
    display: flex;
}

.legendSquare {
    width: 16px;
    height: 16px;
    margin-right: 5px;
}

.legendText {
    vertical-align: middle;
    height: 100%;
}
</style>
