<template>
    <div id="NominasComponent" v-show="visible">
        <div>
            <h1 class="cabecera">Recibos</h1>
            <hr />
            <div class="divTable">
                <div class="divTableBody">
                    <div class="divTableRow">
                        <div
                            class="divTableCell divTableCellCenter"
                            style="width: 10%"
                        >
                            <i
                                class="fa-solid fa-file-invoice-dollar fa-4x"
                            ></i>
                        </div>
                        <div class="divTableCell">
                            En esta sección podrá consultar sus recibos de
                            nóminas.
                            <ul class="listaLinks">
                                <li>
                                    <a
                                        href="#"
                                        @click="
                                            OpenCloseModalNominas(
                                                !showModalNominas
                                            )
                                        "
                                        >Recibos de nóminas</a
                                    >
                                </li>
                                <modal-nominas
                                    :visible="showModalNominas"
                                    v-on:visibleChild="OpenCloseModalNominas"
                                >
                                </modal-nominas>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <h1 class="cabecera">Certificado</h1>
            <hr />
            <div class="divTable">
                <div class="divTableBody">
                    <div class="divTableRow">
                        <div
                            class="divTableCell divTableCellCenter"
                            style="width: 10%"
                        >
                            <i class="fa-solid fa-file-contract fa-4x"></i>
                        </div>
                        <div class="divTableCell">
                            En esta sección podrá descargar el certificado de
                            retenciones del ejercicio anterior.
                            <ul class="listaLinks">
                                <li>
                                    <a
                                        href="#"
                                        @click="
                                            OpenCloseModalCertificados(
                                                !showModalCertificados
                                            )
                                        "
                                        >Certificado de retenciones</a
                                    >
                                </li>
                                <modal-certificados
                                    :visible="showModalCertificados"
                                    v-on:visibleChild="
                                        OpenCloseModalCertificados
                                    "
                                >
                                </modal-certificados>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ModalNominas from "./ModalNominas.vue";
    import ModalCertificados from "./ModalCertificados.vue";
    export default {
        name: "Nominas",
        props: ["visible"],
        components: {
            ModalNominas,
            ModalCertificados,
        },
        data() {
            return {
                showModalNominas: false,
                showModalCertificados: false,
            };
        },
        methods: {
            OpenCloseModalNominas: function (value) {
                this.showModalNominas = value;
            },
            OpenCloseModalCertificados: function (value) {
                this.showModalCertificados = value;
            },
        },
    };
</script>
