<template>
    <div class="pageShow">
        <NoticiasCarousel v-if="!smallDevice" />
        <MenuLateral id="menuLateralComponent" :visible="showMenu" v-on:menuChange="setFooter" />
        <div class="contenido" :class="{ absolutePositionMenu: smallDevice }">
            <BigMenuVue v-show="typeof contentShowed === 'string' && contentShowed.includes('bigmenu')" v-on:resized="setFooter" />
            <News :visible="contentShowed == 1" />
            <DatosPersonales :visible="contentShowed == 2" v-on:loaded="setFooter" />
            <Nominas :visible="contentShowed == 3" />
            <SolicitudVacaciones :visible="contentShowed == 4" />
            <AprobacionVacaciones :visible="contentShowed == 5" />
            <GeneralView :visible="contentShowed ==6" />
            <MainWellnessArea
                v-show="contentShowed == 9 || (typeof contentShowed === 'string' && contentShowed.includes('9>'))"
                v-on:resized="setFooter"
            />
            <InterestInfo v-show="contentShowed == 11" v-on:resized="setFooter" />
        </div>
        <Footer :class="{ fixedFooter }" class="footer" />
    </div>
</template>

<script>
    import NoticiasCarousel from "../components/NoticiasCarousel.vue";
    import MenuLateral from "../components/MenuLateral.vue";
    import DatosPersonales from "../components/DatosPersonales.vue";
    import Nominas from "../components/Nominas.vue";
    import News from "../components/NewsPage.vue";
    import SolicitudVacaciones from "../components/vacaciones/SolicitudVacaciones.vue";
    import AprobacionVacaciones from "../components/vacaciones/AprobacionVacaciones.vue";
    import GeneralView from "../components/vacaciones/GeneralView.vue";
    import MainWellnessArea from "../components/wellness-area/MainWellnessArea.vue";
    import InterestInfo from "../components/interest-info/InterestInfo.vue";
    import Footer from "../components/Footer.vue";
    import BigMenuVue from "../components/BigMenu.vue";

    export default {
        name: "App",
        components: {
            NoticiasCarousel,
            MenuLateral,
            DatosPersonales,
            Nominas,
            News,
            SolicitudVacaciones,
            AprobacionVacaciones,
            GeneralView,
            MainWellnessArea,
            Footer,
            BigMenuVue,
            InterestInfo,
        },
        data() {
            return {
                showMenu: true,
                showMenuCompacto: false, //Revisar en el futuro utilidad de esta variable
                noticiasOn: false,
                smallDevice: false,
                fixedFooter: false,
            };
        },
        methods: {
            resizeContent: function () {
                this.smallDevice = document.documentElement.clientWidth < 600;
                this.setFooter();
            },
            setFooter: function (overflow = 0) {
                setTimeout(() => {
                    let htmlHeight = document.getElementsByTagName("html")[0].offsetHeight;
                    let deviceHeight = document.documentElement.clientHeight;
                    if (deviceHeight > htmlHeight + 40 != this.fixedFooter) {
                        this.fixedFooter = deviceHeight > htmlHeight + 40;
                    } else if (overflow <= 10) {
                        this.setFooter(overflow + 1);
                    }
                }, 10);
            },
        },
        computed: {
            authToken: function () {
                return this.$store.getters.getToken;
            },
            contentShowed: function () {
                return this.$store.getters.getVisibleMenu;
            },
        },
        created() {
            window.addEventListener("resize", this.resizeContent);
        },
        unmounted() {
            window.removeEventListener("resize", this.resizeContent);
        },
        mounted() {
            this.resizeContent();
            if (this.smallDevice) this.$store.commit("setVisibleMenu", 1);
            else this.$store.commit("setVisibleMenu", "bigmenu1");
        },
        watch: {
            contentShowed: function () {
                this.setFooter();
            },
        },
    };
</script>

<style>
    .noticiaLink {
        text-decoration: none !important;
    }
    .noticiaNoLink {
        pointer-events: none;
        cursor: default;
        text-decoration: none !important;
    }

    .pageShow {
        display: flex;
        flex-flow: column;
        height: 100%;
        position: relative;
    }

    #menuLateralComponent {
        flex: 0 1 auto;
        z-index: 1000;
        width: 100%;
    }

    .contenido {
        width: calc(100% - 40px);
        padding: 20px;
        height: max(auto, 500px);
    }

    .absolutePositionMenu {
        position: absolute;
        top: 100px;
        /*width: calc(100% - 60px);*/
        overflow: auto;
    }

    .fixedFooter {
        position: fixed;
        bottom: 0;
    }

    .footer {
        bottom: 0;
    }

    @import "../../public/styles/styles.css";
</style>
