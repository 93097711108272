<template>
    <div class="error" v-if="error != null && error != ''"><i class="fa-solid fa-circle-exclamation"></i> {{ error }}</div>
    <div class="container">
        <div class="form">
            <form @submit.prevent="sendForm">
                <div class="formError" v-if="formError != null && formError != ''" v-html="formError"></div>
                <div class="form-group center">Bienvenido</div>
                <div class="form-group">
                    <!-- <label for="user">NIF: <span class="mandatory">Obligatorio</span></label> -->
                    <input
                        type="text"
                        id="user"
                        name="user"
                        required
                        aria-required="true"
                        aria-label="NIF"
                        placeholder="NIF"
                        aria-placeholder="NIF"
                        v-model="loginData.user"
                    />
                </div>
                <div class="form-group" style="text-align: left">
                    <!-- <label for="password">Contraseña: <span class="mandatory">Obligatorio</span></label> -->
                    <input
                        :type="showPassword ? 'text' : 'password'"
                        name="password"
                        id="password"
                        required
                        aria-required="true"
                        aria-label="Contraseña"
                        placeholder="Contraseña"
                        aria-placeholder="Contraseña"
                        v-model="loginData.password"
                    />
                    <label class="container-checkbox" for="showPassword"
                        >Mostrar contraseña
                        <input
                            type="checkbox"
                            name="showPassword"
                            id="showPassword"
                            class="showPassword"
                            aria-label="Mostrar contraseña"
                            v-model="showPassword"
                        />
                        <span class="mark"></span>
                    </label>
                </div>
                <div class="form-group">
                    <input type="submit" value="Iniciar sesión" aria-label="Iniciar sesión" />
                    <router-link to="passwordrecovery" class="forgottentPassword">¿Has olvidado tu contraseña?</router-link>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
    const md5 = require("md5");

    import Constantes from "../common/constantes.js";
    import { useToast } from "vue-toastification";

    export default {
        data() {
            return {
                loginData: {
                    user: "",
                    password: "",
                },
                formError: "",
                error: "",
                showPassword: false,
                toast: useToast(),
            };
        },
        methods: {
            sendForm: async function () {
                this.formError = "";
                if (this.validateForm()) {
                    const cryptedPassword = Buffer.from(md5(this.loginData.password), "hex").toString("base64");

                    this.apiQuery(Constantes.APIs.Login, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            accept: "*/*",
                        },
                        body: JSON.stringify({
                            user: this.loginData.user.trim().toUpperCase(),
                            password: cryptedPassword,
                        }),
                    })
                        .then((response) => {
                            if (response.ok) {
                                response.json().then((result) => {
                                    this.$store.commit("setToken", result.ticket);
                                    this.$store.commit("setRefreshToken", result.refreshToken);
                                    this.$store.commit("setPasswordChange", result.firstLogin);
                                    this.$store.commit("setBtrlList", result.btrlList);
                                    if (!/^Z[0-9]+$/.test(result.personalDataDTO.codHandicapped)) result.personalDataDTO.codHandicapped = "ZS";
                                    this.$store.commit("setUserData", result.personalDataDTO);
                                    this.$store.commit("setSapCode", result.pernrList);
                                    this.$store.commit("setIsSpecialCenter", result.isSpecialCenter);
                                    this.$router.push("/");
                                });
                            } else if (response.status == 500) {
                                this.formError = "Ha ocurrido un error, intentalo de nuevo más tarde";
                            } else if (response.status == 401) {
                                response
                                    .text()
                                    .then((body) => {
                                        if (body.includes('blocked: "')) {
                                            this.formError =
                                                'Tu cuenta ha sido bloqueada, para desbloquear el acceso, restablece la contraseña haciendo click abajo en <i>"¿Has olvidado tu contraseña?"</i>';
                                        } else if (body.includes("code:458")) {
                                            this.formError = "Usuario o contraseña incorrectos";
                                        } else {
                                            this.formError = "Ha ocurrido un error, intentalo de nuevo más tarde";
                                        }
                                    })
                                    .catch(() => {
                                        this.formError = "Ha ocurrido un error inesperado, intentalo de nuevo más tarde";
                                    });
                            } else {
                                this.formError = "Ha ocurrido un error inesperado, intentalo de nuevo más tarde";
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            },
            validateForm: function () {
                let returnValue = true;
                if (this.loginData.user == "") {
                    document.getElementById("user").style.border = "2px solid red";
                    document.getElementById("user").focus();
                    this.formError = "El campo NIF es obligatorio";
                    returnValue = false;
                }
                if (this.loginData.password == "") {
                    document.getElementById("password").style.border = "2px solid red";
                    if (this.loginData.user != "") {
                        document.getElementById("password").focus();
                        this.formError = "El campo contraseña es obligatorio";
                    }
                    this.formError = "Los campos NIF y contraseña son obligatorios";
                    returnValue = false;
                }
                return returnValue;
            },
        },
        mounted() {
            if (this.$route?.query?.status == "expiredSession") {
                this.toast.info("Tu sesión ha expirado, vuelve a iniciar sesión.", {
                    timeout: 5000,
                });
            }
        },
    };
</script>

<style scoped>
    .form-group,
    input,
    button {
        font-size: 29px;
    }

    .error {
        z-index: 1;
        width: 100%;
        background-color: #c0392b;
        position: absolute;
        top: 0;
        left: 0;
    }

    .center {
        text-align: center;
    }

    .formError {
        color: white;
        background-color: red;
        font-weight: 550;
        font-size: 15px;
        width: calc(100% - 10px);
        padding: 5px;
        text-align: center;
        border-radius: 5px 5px 0 0;
        top: 0;
        left: 0;
    }

    .container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        z-index: -1;
        background-image: url("../assets/background.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center top;
    }

    .mandatory {
        color: red;
        font-size: 15px;
        vertical-align: top;
    }

    div.form {
        border: 0;
        background-color: #002e72;
        border-radius: 5px;
        color: white;
        width: 440px;
        height: 440px;
        margin: auto;
        top: 50%;
        -webkit-box-shadow: 2px 2px 7px 2px #000000;
        box-shadow: 2px 2px 7px 2px #000000;
    }

    .form-group {
        margin: 35px;
        text-align: center;
    }

    .form-group > input {
        width: calc(100% - 25px);
        border: 0;
        border-radius: 5px;
    }

    .form-group > input:focus {
        background-color: #cce0ff;
    }

    .form-group > input[type="submit"] {
        width: 100%;
        transition: ease 0.3s;
        border: 0;
        border-radius: 5px;
        background-color: #5590e7;
        height: 50px;
    }

    .form-group > input[type="submit"] {
        background-color: #6ba4f8;
        cursor: pointer;
    }

    .form-group > input[type="submit"]:focus {
        background-color: #6ba4f8;
    }

    .form-group > * {
        display: block;
    }

    label.showPassword {
        color: white;
        font-size: 17px;
        font-weight: 200;
        margin-top: 12px;
        transition: all ease 0.3s;
        text-decoration: none;
    }

    .container-checkbox {
        display: inline;
        position: relative;
        padding-left: 35px;
        margin-bottom: 20px;
        cursor: pointer;
        font-size: 17px;
        vertical-align: middle;
        width: 100%;
        height: 20px;
    }

    /* Hide the default checkbox */
    .container-checkbox input {
        visibility: hidden;
        cursor: pointer;
    }

    /* Create a custom checkbox */
    .mark {
        position: absolute;
        top: 0;
        left: 0;
        height: 15px;
        width: 15px;
        background-color: white;
        border: 2px solid white;
        border-radius: 100%;
    }

    .container-checkbox:hover input ~ .mark {
        background-color: lightgray;
    }

    .container-checkbox input:checked ~ .mark {
        background-color: white;
    }

    /* Create the mark/indicator (hidden when not checked) */
    .mark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the mark when checked */
    .container-checkbox input:checked ~ .mark:after {
        display: block;
    }

    /* Style the mark/indicator */
    .container-checkbox .mark:after {
        left: 4px;
        top: 1px;
        width: 5px;
        height: 10px;
        border: solid blue;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
    }
</style>
