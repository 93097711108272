<template>
    <div id="MenuLateralComponent" class="MenuLateral" v-show="!smallDevice || menuVisibility">
        <a class="closeButton" id="btnClose" @click="CloseMenu"></a>
        <ul :class="{ smallDevice: smallDevice }">
            <li v-for="m in filteredMenuData" :key="m.idMenu" :id="m.idMenu"
                v-show="smallDevice || m.titulo != 'Noticias'" @click.self="SeleccionarMenu(m)" class="liParent" :class="{
                    smallDevice: smallDevice,
                    seleccionado: menuSeleccionado === m.idMenu || (typeof menuSeleccionado == 'string' && menuSeleccionado.includes(m.idMenu + '>')),
                    logoutFloat: m.type == 'logout' && !smallDevice,
                }">
                <!-- GetClass(m), -->
                <i class="customIcons" :class="m.icono" @click="SeleccionarMenu(m)"></i>
                <!-- <font-awesome-icon :icon="m.icono" size="2x" class="fa-fw customIcons" /> -->
                <template v-if="m.notificaciones" @click="SeleccionarMenu(m)">
                    <span class="badge">{{ m.notificaciones }}</span>
                </template>
                <a :class="{ smallDevice: smallDevice }" @click="SeleccionarMenu(m)">{{ m.titulo }}</a>
                <!-- <ul v-if="m.children != undefined && m.children.length > 0" class="ulChilds" :class="{ smallDevice }">-->
                <ul v-if="false">
                    <li v-for="mc in m.children" :key="mc.idMenu" :id="mc.idMenu" v-show="MostrarElemento(mc)"
                        @click="SeleccionarSubMenu(mc)" class="liChild" :class="{ smallDevice }">
                        <!--<i :class="mc.icono" class="customIcons fa-2x"></i>-->
                        <!-- <font-awesome-icon :icon="mc.icono" size="2x" class="fa-fw customIcons" /> -->
                        <template v-if="mc.notificaciones">
                            <span class="badge">{{ mc.notificaciones }}</span>
                        </template>
                        <a :class="{ smallDevice: smallDevice }"><i class="customIcons" :class="mc.icono"></i> {{
                                mc.titulo
                        }}</a>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
import { APIs, menus } from "../common/constantes.js";

export default {
    name: "MenuLateral",
    props: ["visible"],
    data() {
        return {
            smallDevice: false,
            currentUser: {
                Id: 1,
                EsSupervisor: false,
            },
            datosMenu: menus,
            codVisibilityNoHandicapped: '1',
            codVisibilityHandicapped: '2',
            visibilityNoHandicapped: "ZS",
        };
    },
    computed: {
        user: function () {
            return this.$store.getters.getUser;
        },
        menuVisibility: function () {
            return this.$store.getters.getMenuVisibility;
        },
        authToken: function () {
            return this.$store.getters.getToken;
        },
        filteredMenuData: function () {
            return this.datosMenu.filter((row) => (!row.usuarioSupervisor || row.usuarioSupervisor == this.currentUser.EsSupervisor) && row.show);
        },
        responsableNotifications: function () {
            return this.$store.getters.getResponsableNotifications;
        },
        menuSeleccionado: function () {
            return this.$store.getters.getVisibleMenu;
        },
    },
    watch: {
        responsableNotifications: function (newValue) {
            //    this.datosMenu[4].notificaciones = newValue;
            this.datosMenu.find(r => r.idMenu == "bigmenu2").notificaciones = newValue;
        },
    },
    methods: {
        GetClass: (m) => {
            if (m.children != undefined && m.children.length > 0) return "";
            return "liParent";
        },
        CloseMenu: function () {
            this.$emit("visibleChild", !this.visible);
        },
        SeleccionarMenu: function (Menu) {
            let idMenu = Menu.idMenu;
            /**if (this.datosMenu[idMenu - 1].children.length > 0) {
                let elementos = document.getElementsByTagName("li");
                for (let i = 0; i < elementos.length; i++) {
                    if (elementos[i].id == idMenu) {
                        if (
                            elementos[i].className.indexOf(
                                "seleccionado"
                            ) === -1
                        )
                            elementos[i].className += "seleccionado";
                    } else {
                        elementos[i].className = elementos[
                            i
                        ].className.replace(" seleccionado", "");
                    }
                }
                return;
            }*/
            this.$store.commit("setMenuVisibility", false);
            if (Menu.type == "logout") {
                this.$store.commit("logout");
                window.location.reload();
                return;
            } else if (Menu.type == "changePassword") {
                this.$router.push({ name: "passwordChange" });
                return;
            } else if (Menu.type == "href") {
                window.open(Menu.url, "_blank");
                return;
            } else if (Menu.type == "none") {
                return;
            } else if (Menu.type == "bigmenu") {
                this.$store.commit("setVisibleMenu", idMenu);
                this.$emit("menuChange");
                return;
            }

            this.$store.commit("setVisibleMenu", idMenu);
            this.$emit("menuChange");
        },
        SeleccionarSubMenu: function (submenu) {
            if (submenu.type == "submenu") {
                this.$store.commit("setVisibleMenu", submenu.idMenu);
                this.$emit("menuChange");
            } else {
                this.SeleccionarMenu(submenu);
            }
        },
        MostrarElemento: function (menu) {
            //let esunsupervisor=this.currentUser.EsSupervisor;

            let mostrar = true;
            // se previene el fallo de la primera entrada.
            if (this.currentUser.Id == "undefined" || this.currentUser.Id == null) {
                if (menu.usuarioSupervisor) {
                    mostrar = false;
                }
            } else {
                if (!this.currentUser.EsSupervisor || this.currentUser.EsSupervisorisor == "undefined" || this.currentUser.EsSupervisor == null) {
                    this.currentUser.EsSupervisor = false;
                    if (menu.usuarioSupervisor && !this.currentUser.EsSupervisor) {
                        // si el menú requiere un supervisor y no lo somos no lo mostramos.
                        mostrar = false;
                    } else {
                        //if(menu.usurioServicio && !esunsupervisor) {
                        //    mostrar = false;
                        //}
                    }
                } else {
                    if (menu.usuarioSupervisor && !this.currentUser.EsSupervisor) {
                        // si el menú requiere un supervisor y no lo somos no lo mostramos.
                        mostrar = false;
                    } else {
                        //if(menu.usurioServicio && !esunsupervisor) {
                        //    mostrar = false;
                        //}
                    }
                }
            }
            return mostrar;
        },
        resizeMenu: function () {
            this.smallDevice = document.documentElement.clientWidth < 600;
        },
        getAprobacionesLength: async function () {
            this.apiQuery(APIs.getAbsencesRepresentative, {
                method: "POST",
                mode: "cors",
                headers: {
                    Authorization: "Bearer " + this.authToken,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    dnirepresentative: this.user,
                }),
            })
                .then((response) => response.json())
                .then((_result) => {
                    if (_result) {
                        let result = _result.filter((row) => row.status == "PENDING");
                        if (result.length > 0) {
                            this.$store.commit("setResponsableNotifications", result.length);
                        }
                        this.currentUser.EsSupervisor = _result.length > 0;
                    }
                })
                .catch((error) => console.log(error));
        },
        setWellnesAreaSubmenus: function () {
            this.apiQuery(APIs.getSections, {
                method: "POST",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.authToken,
                },
                body: JSON.stringify({
                    codeNvCategory: 1,
                    visibility: this.codHandicapped == this.visibilityNoHandicapped ? this.codVisibilityNoHandicapped : this.codVisibilityHandicapped, //Cod sin discapacidad o con discapacidad
                }),
            })
                .then((response) => response.json())
                .then((result) => {
                    result.forEach((card) => {
                        this.datosMenu[7].children.push({
                            idMenu: "9>" + card.code,
                            icono: "",
                            notificacion: null,
                            titulo: card.name,
                            type: "submenu",
                        });
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    mounted() {
        //this.SeleccionarMenu(this.menuSeleccionado);
        this.resizeMenu();
        this.getAprobacionesLength();
        //this.setWellnesAreaSubmenus();
    },
    created() {
        window.addEventListener("resize", this.resizeMenu);
    },
    unmounted() {
        window.removeEventListener("resize", this.resizeMenu);
    },
};
</script>

<style scoped>
.MenuLateral {
    width: 100%;
    height: 100%;
    background-color: #002e72;
    position: relative;
}

ul {
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: inline-block;
    background-color: #002e72;
}

ul.smallDevice {
    top: 100px;
    height: 100vh;
    position: fixed;
}

ul li {
    display: inline-block;
    padding: 10px;
    margin: 0;
    position: relative;
}

.liParent {
    overflow-wrap: break-word;
    transition: all 0.2s ease-in-out;
}

.liParent.smallDevice {
    width: 100%;
}

.liParent:hover {
    background-color: #0070ba;
    cursor: pointer;
}

ul li svg {
    vertical-align: middle;
}

ul li a {
    color: white;
}

a {
    text-decoration: none;
}

a.smallDevice {
    font-size: 20px;
}

.seleccionado {
    background-color: #f49600;
}

.closeButton {
    cursor: pointer;
    color: #fff;
}

.badge {
    position: absolute;
    left: 28px;
    top: 2px;
    padding: 2px 6px;
    border-radius: 50%;
    background: red;
    color: white;
}

.customIcons {
    color: white;
    margin-right: 20px;
}

ul li ul:not(.smallDevice) {
    display: none;
}

ul li:hover ul:not(.smallDevice) {
    display: block;
    position: absolute;
    left: 0;
    top: 39px;
    width: auto;
}

ul li ul li:not(.smallDevice) {
    width: 100%;
    -webkit-box-shadow: 5px 5px 12px -1px gray;
    box-shadow: 5px 5px 12px -1px gray;
}

.liChild {
    width: 100%;
    padding: 10px 35px 10px 10px;
    transition: all 0.2s ease-in-out;
    background-color: #002e72;
}

.ulChilds.smallDevice {
    display: none;
}

.liChild:hover {
    background-color: #0070ba;
}

.logoutFloat {
    float: right;
}
</style>
