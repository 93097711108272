const Entorno = "LIVE"; // 'LIVE'  para pro | 'QUA' para uat-qua
const PuertoEntorno = {
    QUA: "https://opentext.quercus.com.es:8443",
    LIVE: "https://opentext.quercus.com.es:443",
    JDEV: "http://localhost:8080",
};
export const APIs = {
    Login: PuertoEntorno[Entorno] + "/sifu-ot-services/portalempleado/usuario/conexion",
    ChangePassword: PuertoEntorno[Entorno] + "/sifu-ot-services/portalempleado/usuario/cambio",
    PersonalData: PuertoEntorno[Entorno] + "/sifu-ot-services/portalempleado/usuario/datospersonales?dni=",
    certificados: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/certificado/list?dni=",
    DescargaDocumentos: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/document/downloadContent?documentId=",
    nominas: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/nomina/list?dni=",
    AddressData: PuertoEntorno[Entorno] + "/sifu-ot-services/portalempleado/usuario/datosdomicilio?dni=",
    ContactData: PuertoEntorno[Entorno] + "/sifu-ot-services/portalempleado/usuario/datoscontacto?dni=",
    FamilyData: PuertoEntorno[Entorno] + "/sifu-ot-services/portalempleado/usuario/datosfamilia?dni=",
    PasswordRecovery: PuertoEntorno[Entorno] + "/sifu-ot-services/portalempleado/usuario/recuperacion?dni=",
    News: PuertoEntorno[Entorno] + "/sifu-ot-services/portalempleado/usuario/pancartas",
    holidaysRequest: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/ausencias/crear",
    getHolidays: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/ausencias/solicitudes",
    getAbsencesRepresentative: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/ausencias/solicitudesRepresentante",
    getAllRepresentative: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/ausencias/representados",
    getDaysData: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/ausencias/vacaciones",
    rejectAbsence: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/ausencias/rechazar",
    approveAbsence: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/ausencias/aceptar",
    cancelAbsence: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/ausencias/cancelar",
    getSections: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/cms/getSubsections",
    getMainSections: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/cms/getSubsectionsMain",
    getPage: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/cms/getPage",
    getImageSection: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/cms/getImageSection",

    refreshToken: PuertoEntorno[Entorno] + "/sifu-ot-services/portalempleado/usuario/refreshToken",
};

export const REST_CODES = {
    UNAUTHORIZED: 401,
};

export const Holidays = [[1, 6], [], [], [], [1], [], [], [15], [], [12], [1], [6, 8, 25]];

export const menuIds = {
    wellnesArea: 9,
    personalData: 2,
    holidaysApprove: 5,
    holidaysRequest: 4,
    interestInfo: 11,
};

export const menus = [
    {
        idMenu: "bigmenu1",
        icono: "fa-solid fa-building",
        notificaciones: null,
        children: [
            {
                idMenu: 2,
                icono: "fa-solid fa-address-card",
                notificaciones: null,
                children: [],
                titulo: "Datos personales",
                image: "https://cdn.pixabay.com/photo/2015/07/17/22/43/student-849825_960_720.jpg",
                usuarioSupervisor: false,
                type: "menu",
            },
            {
                idMenu: 3,
                icono: "fa-solid fa-building-columns",
                notificaciones: null,
                children: [],
                titulo: "Recibos",
                image: "https://cdn.pixabay.com/photo/2014/09/20/13/54/calculator-453792_960_720.jpg",
                usuarioSupervisor: false,
                type: "menu",
            },
            {
                idMenu: 4,
                icono: "fa-solid fa-umbrella-beach",
                notificaciones: null,
                children: [],
                titulo: "Vacaciones",
                image: "https://cdn.pixabay.com/photo/2020/02/16/07/55/beach-4852830_960_720.jpg",
                usuarioSupervisor: false,
                type: "menu",
            },
            {
                idMenu: 7,
                icono: "fa-solid fa-briefcase",
                notificaciones: null,
                children: [],
                titulo: "Ofertas de empleo",
                usuarioSupervisor: false,
                type: "href",
                url: "https://www.gruposifu.com/trabaja-nosotros",
                image: "",
            },
            {
                idMenu: 11,
                icono: "fa-solid fa-briefcase",
                notificaciones: null,
                children: [],
                titulo: "Información de interés",
                usuarioSupervisor: false,
                type: "menu",
                image: "https://cdn.pixabay.com/photo/2016/11/22/19/24/archive-1850170_960_720.jpg",
            },
        ],
        titulo: "Área laboral",
        usuarioSupervisor: false,
        type: "bigmenu",
        show: true,
    },
    {
        idMenu: "bigmenu2",
        icono: "fa-solid fa-person-circle-check",
        notificaciones: null,
        children: [
            {
                idMenu: 5,
                icono: "fa-solid fa-person-circle-check",
                notificaciones: null,
                children: [],
                titulo: "Aprobar Vacaciones",
                usuarioSupervisor: false,
                
                type: "menu",
            },
            {
                idMenu: 6,
                icono: "fa-solid fa-table",
                notificaciones: null,
                children: [],
                titulo: "Vista Vacaciones",
                usuarioSupervisor: false,
                type: "menu",
            },
        ],
        titulo: "Responsables",
        usuarioSupervisor: true,
        type: "bigmenu",
        show: true,
    },
    {
        idMenu: 1,
        icono: "fa-solid fa-newspaper",
        notificaciones: null,
        children: [],
        titulo: "Noticias",
        usuarioSupervisor: false,
        type: "menu",
    },

    {
        idMenu: 9,
        icono: "fa-solid fa-spa",
        notificaciones: null,
        children: [],
        titulo: "Club del Empleado/a",
        usuarioSupervisor: false,
        type: "menu",
        show: true,
    },
    {
        idMenu: "bigmenu3",
        icono: "fa-solid fa-briefcase",
        notificaciones: null,
        children: [
            {
                idMenu: 8,
                icono: "fa-solid fa-key",
                notificaciones: null,
                children: [],
                titulo: "Cambiar contraseña",
                usuarioSupervisor: false,
                type: "changePassword",
                image: "https://cdn.pixabay.com/photo/2018/07/12/21/32/subscribe-3534409_960_720.jpg",
            },
        ],
        titulo: "Perfil de usuario",
        usuarioSupervisor: false,
        type: "bigmenu",
        show: true,
    },
    {
        idMenu: 10,
        icono: "fa-solid fa-power-off",
        notificaciones: null,
        children: [],
        titulo: "Cerrar sesión",
        usuarioSupervisor: false,
        type: "logout",
        show: true,
    },
];

export const noImage = "https://cdn.onlinewebfonts.com/svg/img_98811.png";

export default { APIs };
