<template>
    <div>
        <div>
            <div class="breadCrumb">
                <div v-for="(value, key) in breadcrumb" :key="key" class="crumbContainers">
                    <label class="crumb" @click="restoreTo(value, key)">{{ getBreadCrumbLabel(value.id) }}</label>
                    <label v-if="key != breadcrumb.length - 1" style="margin-left: 6px; margin-right: 5px; padding-right: 0; padding-left: 0;">/</label>
                </div>
            </div>
        </div>

        <div class="cardGroup" v-if="!content.title && !loadingCards">
            <div
                v-for="(card, index) in cards"
                :key="card.id"
                class="card small"
                @click="getCardsData(card.id)"
                :style="{
                    'background-image': `url('${card.image}')`,
                }"
            >
                <div v-if="card.pageImportant" class="pageImportant">
                    <img class="imgNovelty" src="../../assets/novelty.png" style="width: 100px" alt="Novedad" />  
                </div>
                <div class="cardBody small">
                    <h2>{{ card.title }}</h2>
                    <p class="cardDescription" :title="card.description" :style="cardDescriptionStyle[index]">{{ card.description }}</p>
                </div>
            </div>
        </div>
        <div class="newsGroup" v-if="!content.title && !loadingCards">
            <div v-for="news in newsList" :key="news.id" class="newsCard" @click="requestContent(news.id)">
                <div
                    class="newsImage"
                    :style="{
                        'background-image': `url('${news.image}')`,
                    }"
                ></div>
                <div class="newsContent">
                    <div class="newsHeader">
                        <div class="newsDate">{{ news.date.format("DD-MM-YYYY") }}</div>
                        <div class="newsTitle">{{ news.title }}</div>
                    </div>

                    <div class="newsDescription">
                        {{ news.description }}
                    </div>
                </div>
            </div>
        </div>
        <div class="content" v-if="content.title">
            <div
                class="headImage"
                :style="{
                    'background-image': `url('${content.image}')`,
                }"
                v-if="content.image"
            >
                <h1 class="headTitle">{{ content.title }}</h1>
            </div>
            <h1 v-else>{{ content.title }}</h1>
            <p v-html="content.content"></p>
            <div v-if="mobilePage" class="mobilePage"></div>
        </div>
        <div style="text-align: center" v-if="!content.title && !loadingCards && cards.length <= 0 && newsList.length <= 0">
            <h2>No hay ningun contenido que mostrar aquí</h2>
            <br />
            <img src="https://images.emojiterra.com/google/android-oreo/512px/2639.png" style="width: 250px" />
        </div>
    </div>
</template>
<script>
import { APIs, menuIds } from "../../common/constantes.js";
//import moment from "moment";
const menuId = menuIds.wellnesArea;

export default {
    name: "Wellnes Area",
    data() {
        return {
            cards: [],
            newsList: [],
            data: [],
            content: {
                image: "",
                title: "",
                content: "",
            },
            breadcrumb: [{ id: -1, title: "Club del empleado/a" }],
            loadingCards: false,
            memoization: [],
            contentMemoization: [],
            firstContentMemo: null,
            nameStores: [],
            visibility: 0,
            cardTitleLength:7,
            codVisibilityNoHandicapped:'1',
            codVisibilityHandicapped:'2',
            visibilityNoHandicapped:"ZS",
            mobilePage: this.usingInMobile(),

        };
    },
    methods: {
        getCardsData: function (id) {
            if (this.loadingCards) return;

            let pageCode = this.cards.find((card) => card.id == id + "")?.pageCode;

            this.loadingCards = true;
            this.cards = [];
            this.newsList = [];

            if (pageCode) {
                this.requestContent(pageCode);
                return;
            }

            if (id && this.memoization[id]) {
                this.cards = this.memoization[id].cards;
                this.newsList = this.memoization[id].newsList;
                this.loadingCards = false;
                this.breadcrumb.push(this.memoization[id].breadcrumb);
                this.$emit("resized");
                return;
            } else if (!id && this.firstContentMemo) {
                this.cards = this.firstContentMemo.cards;
                this.newsList = this.firstContentMemo.newsList;
                this.loadingCards = false;
                this.$emit("resized");
                return;
            }
            this.apiQuery(id ? APIs.getSections : APIs.getMainSections, {
                method: "POST",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.authToken,
                },
                body: JSON.stringify({
                    codeSubsection: id,
                    visibility: this.codHandicapped==this.visibilityNoHandicapped?this.codVisibilityNoHandicapped:this.codVisibilityHandicapped, //Cod sin discapacidad o con discapacidad
                    typeDocument: "TYPE_PAGE",
                    typeSection: "TYPE_SECTION_PAGE",
                    draft: "N",
                    pageValid: "Y",
                    specialEmployment: this.$store.getters.getIsSpecialCenter=='false'?'2':'1',
                    user: this.$store.getters.getBtrlList?.includes("E0") ? "1" : "2",
                }),
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result && result.length) {
                        result.forEach((card) => {

                            this.cards.push({
                                id: card.code,
                                image: card.image,
                                title: card.name,
                                description: card.description,
                                pageCode: card.pageCode,
                                pageImportant: card.pageImportant,
                            });
                            this.nameStores[card.code] = card.name;
                        });
                    }

                    this.loadingCards = false;

                    if (this.cards.length > 0) {
                        this.cards.forEach((card) => {
                            this.apiQuery(APIs.getImageSection, {
                                method: "POST",
                                headers: {
                                    accept: "application/json",
                                    "Content-Type": "application/json",
                                    Authorization: "Bearer " + this.authToken,
                                },
                                body: card.id,
                            })
                                .then((response) => response.text())
                                .then((result) => {
                                    card.image = result;
                                });
                        });
                    }
                    if (id) {
                        this.memoization[id] = {
                            cards: this.cards,
                            newsList: this.newsList,
                            breadcrumb: { id: id, title: this.nameStores[id] },
                        };
                        if (!this.breadcrumb.find((crumb) => crumb.id == id)) this.breadcrumb.push(this.memoization[id].breadcrumb);
                    } else {
                        this.firstContentMemo = {
                            cards: this.cards,
                            newsList: this.newsList,
                        };
                        this.startFillingMemoization();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        startFillingMemoization: function () {
            this.firstContentMemo.cards.forEach((card) => {
                this.fillMemoization(card.id);
            });
        },     
          usingInMobile: function () {
            if (navigator.userAgent.match('Android') || navigator.userAgent.match('webOS') || navigator.userAgent.match('iPhone') || navigator.userAgent.match('iPad') || navigator.userAgent.match('iPod') || navigator.userAgent.match('BlackBerry') || navigator.userAgent.match('Windows Phone')) {
                return true;
            } else {
                return false;
            }
        },

        fillMemoization: async function (id) {

            this.apiQuery(APIs.getSections, {
                method: "POST",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.authToken,
                },
                body: JSON.stringify({
                    codeSubsection: id,
                    visibility: this.codHandicapped==this.visibilityNoHandicapped?this.codVisibilityNoHandicapped:this.codVisibilityHandicapped, //Cod sin discapacidad o con discapacidad
                    typeDocument: "TYPE_PAGE",
                    typeSection: "TYPE_SECTION_PAGE",
                    draft: "N",
                    pageValid: "Y",
                    specialEmployment: this.$store.getters.getIsSpecialCenter=='false'?'2':'1',

                    user: this.$store.getters.getBtrlList?.includes("E0") ? "1" : "2",
                }),
            })
                .then((response) => response.json())
                .then((result) => {
                    let _cards = [];

                    if (result && result.length) {
                        result.forEach((card) => {
                            if (card.pageCode) {
                                this.requestContent(card.pageCode, true);
                            } else {
                                this.fillMemoization(card.code);
                            }
                            _cards.push({
                                id: card.code,
                                image: card.image,
                                title: card.name,
                                description: card.description,
                                pageCode: card.pageCode,
                                pageImportant: card.pageImportant,
                            });
                            this.nameStores[card.code] = card.name;
                        });
                    }
                    if (_cards.length > 0) {
                        _cards.forEach((card) => {
                            this.apiQuery(APIs.getImageSection, {
                                method: "POST",
                                headers: {
                                    accept: "application/json",
                                    "Content-Type": "application/json",
                                    Authorization: "Bearer " + this.authToken,
                                },
                                body: card.id,
                            })
                                .then((response) => response.text())
                                .then((result) => {
                                    card.image = result;
                                });
                        });
                    }
                    this.memoization[id] = {
                        cards: _cards,
                        newsList: this.newsList,
                        breadcrumb: { id: id, title: this.nameStores[id] },
                    };
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        requestContent: function (id, onlyMemo = false) {
            if (!onlyMemo) this.loadingCards = true;
            if (this.contentMemoization[id]) {
                let result = this.contentMemoization[id];
                this.content.image = result.image;
                this.content.title = result.headline;
                this.content.content = result.html;
                this.$emit("resized");
                this.loadingCards = false;
                this.breadcrumb.push({ id: id, title: result.headline, isPage: true });
                return;
            }

            this.apiQuery(APIs.getPage, {
                method: "POST",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.authToken,
                },
                body: id,
            })
                .then((response) => response.json())
                .then((result) => {
                    this.contentMemoization[id] = result;
                    if (!onlyMemo) {
                        this.content.image = result.image;
                        this.content.title = result.headline;
                        this.content.content = result.html;
                        this.loadingCards = false;
                        this.breadcrumb.push({ id: id, title: result.headline, isPage: true });
                        this.$emit("resized");
                    }
                })
                .catch((e) => console.log(e));
        },
        resetContent: function () {
            this.content.image = "";
            this.content.title = "";
            this.content.content = "";
        },
        goToInit: function () {
            this.cards = this.data;
            this.resetContent();
            this.$emit("resized");
        },
        getBreadCrumbLabel: function (value) {
            return this.breadcrumb.find((e) => e.id == value)?.title;
        },
        restoreTo: function (value, key) {
            if (value.isPage) {
                return;
            }
            if (value.id == -1) {
                this.getCardsData(null);
            } else {
                this.getCardsData(value.id);
            }

            this.breadcrumb = this.breadcrumb.slice(0, key + 1);
            this.resetContent();
            this.$emit("resized");
        },
    },
    mounted() {
        this.getCardsData(null);
    },
    computed: {
        contentShowed: function () {
            return this.$store.getters.getVisibleMenu;
        },
        authToken: function () {
            return this.$store.getters.getToken;
        },
        codHandicapped: function () {
            return this.$store.getters.getUserData.codHandicapped;
        },
        cardDescriptionStyle() {
          return this.cards.map((card) => {
                var minusLines = Math.round((card.title.length / 14));
                return {
                    "--cardTitleLength": (this.cardTitleLength - minusLines<1)?1:this.cardTitleLength - minusLines,
                }
            });
        }
    },
    watch: {
        cards: function () {
            this.$emit("resized");
        },
        newsList: function () {
            this.$emit("resized");
        },
        visibility: function () {
            this.memoization = [];
            this.restoreTo(this.breadcrumb[this.breadcrumb.length - 1], this.breadcrumb.length - 1);
        },
        loadingCards: function (newLoading) {
            if (this.contentShowed == menuId) {
                this.$isLoading(newLoading);
                this.$emit("resized");
            }
        },
        contentShowed: function (newContent) {
            if (newContent == menuId) {
                this.$isLoading(this.loadingCards);
            }
        },
    },
};
</script>
<style scoped>
/** Cards con texto en imagen */

.card.small {
    height: 400px;
    display: inline-block;
    position: relative;
    transition: all 0.3s ease;
    -webkit-box-shadow: 5px 5px 12px -1px gray;
    box-shadow: 5px 5px 12px -1px gray;
    margin-bottom: 24px;
    cursor: pointer;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

/* Small Screen */
@media screen and (max-width: 788px) {
    .card.small {
        width: 100%;
        margin-right: 0;
    }
}
/* Medium Screen */
@media screen and (min-width: 788px) {
    .card.small {
        width: 48%;
        height: 350px;
        max-width: 550px;
        margin-right: 1%;
        margin-left: 1%;
    }
}

/* Normal Screen */
@media screen and (min-width: 1200px) {
    .card.small {
        width: 32%;
        height: 350px;
        max-width: 600px;
        margin-right: 0.5%;
        margin-left: 0.5%;
    }
}

/* Big Screen */
@media screen and (min-width: 1800px) {
    .card.small {
        width: 24%;
        height: 350px;
        margin-right: 0.5%;
        margin-left: 0.5%;

    }
}

/* Very Big Screen */
@media screen and (min-width: 2100px) {
    .card.small {
        width: 19%;
        max-width: 800px;

        height: 350px;
        margin-right: 0.5%;
        margin-left: 0.5%;

    }
}

/* Very Big Screen */
@media screen and (min-width: 3000px) {
    .card.small {
        max-width: 13%;

        height: 350px;
        margin-right: 0.5%;
        margin-left: 0.5%;

    }
}

.cardImage.small {
    width: 100%;
    height: 100%;
    position: absolute;
}

.cardBody.small {
    width: 66%;
    height: 54%;
    right: 4%;
    top: 35%;
    background-color: #f5f8fa;
    padding: 10px;
    position: absolute;
    overflow: hidden;
    -webkit-box-shadow: 5px 5px 12px -1px gray;
    box-shadow: 5px 5px 12px -1px gray;
}

/** Cards estiradas */
.card.estirada {
    width: 350px;
    height: 550px;
    display: inline-block;
    position: relative;
    transition: all 0.3s ease;
    -webkit-box-shadow: 5px 5px 12px -1px gray;
    box-shadow: 5px 5px 12px -1px gray;
    margin-right: 5%;
    margin-bottom: 24px;
    cursor: pointer;
    vertical-align: top;
}

.cardBody > h2 {
    font-size: calc(1.3rem - 0.1vw);
}
h2 {
    line-height: normal;
    font-weight: bold;
    margin-top: 0.125em !important;
    margin-bottom: 0.25em !important;

}
.cardImage.estirada {
    width: 100%;
    height: 300px;
}

.cardBody.estirada {
    width: 100%;
    padding: 10px;
}

/** Común para ambos */

.card:hover {
    -webkit-box-shadow: 5px 5px 12px 2px rgb(100, 100, 100);
    box-shadow: 5px 5px 12px 2px rgb(100, 100, 100);
}

.cardBody > p {
    color: gray;
}

.cardBody > h2 {
    color: #002e72;
}


.cardGroup {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 15px;
    padding-right: auto;
    padding-left: auto;
}

.pointer {
    cursor: pointer;
}

.content {
    margin-top: 15px;
}

.breadCrumb {
    display: flex;
    flex-wrap: wrap;
}

.crumb {
    display: flex;
    flex-wrap: nowrap;
    cursor: pointer;
    transition: all 0.3s ease;
    color: #3498db;
    font-weight: normal;
    padding-right: 0; 
    padding-left: 0;
}

.crumb:hover {
    color: #2772a3;
    text-decoration: underline;
}

.crumbContainers {
    display: flex;
    flex-wrap: nowrap;
}

.loadingCard {
    animation: loading 1s infinite ease;
    background-color: #fff;
    background-image: linear-gradient(90deg, #f3f3f3, #eaeaea, #f3f3f3, #eaeaea);
    background-size: 300% 100%;
    width: 350px;
    height: 300px;
    margin-right: 24px;
    margin-bottom: 24px;
    -webkit-box-shadow: 5px 5px 12px -1px #bababa;
    box-shadow: 5px 5px 12px -1px #bababa;
    cursor: pointer;
}
h2{
    line-height: normal;
    font-weight: bold;
    margin-top: 0.25em;
    margin-bottom: 0.25em;  
}
.newsGroup {
    display: grid;
    column-gap: 15px;
    width: 100%;
    margin-top: 15px;
    padding-right: auto;
    padding-left: auto;
}

.newsCard {
    width: 100%;
    height: 300px;
    -webkit-box-shadow: 5px 5px 12px -1px gray;
    box-shadow: 5px 5px 12px -1px gray;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 24px;
    margin-right: 24px;
    cursor: pointer;
    display: inline-flex;
}

/* Small Screen */
@media screen and (max-width: 788px) {
    .newsGroup {
        grid-template-columns: auto;
    }
}
/* Big Screen */
@media screen and (min-width: 788px) {
    .newsGroup {
        grid-template-columns: 50% 50%;
    }
}

.newsHeader {
    flex: 0 1 auto;
}

.newsDate {
    background-color: #f5f8fa;
    padding: 7px;
    float: right;
}

.newsTitle {
    background-color: #f5f8fa;
    padding: 7px;
    font-weight: bolder;
    font-size: 20px;
}

.newsDescription {
    background-color: #f5f8fa;
    padding: 7px;
    width: calc(95% - 14px);
    overflow: hidden;
    flex: 1 1 auto;
}

.newsContent {
    background-color: #f5f8fa;
    width: 75%;
    height: 100%;
    display: flex;
    flex-flow: column;
}

.newsImage {
    width: 25%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

@keyframes loading {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 100% 0;
    }
}

.headImage {
    width: calc(100% + 40px);
    height: 150px;
    margin-left: -20px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    display: flex;
    align-items: center;
}

.headTitle {
    background-color: white;
    border: 1px solid white;
    border-radius: 25px;
    position: absolute;
    left: 2%;
    padding: 5px;
    margin: 0px;
    margin-right: 20px;
}
h2{
        margin: 0;
        margin-top: 3px;

}
.cardDescription {
    margin-top: 0;
    font-size: small;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: var(--cardTitleLength);
    -webkit-box-orient: vertical;
}
.imgNovelty {
    float: left;
}
.mobilePage{
    width: 100%;
    height: 50px;
}
</style>
