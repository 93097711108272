<template>
    <div class="modal-mask" v-if="visible">
        <div class="modal-wrapper" :class="tipoFormulario">
            <div class="modal-container">
                <div class="modal-header">
                    <h2 v-show="tipoFormulario == 'Aprobacion'">Aprobación de vacaciones</h2>
                    <h2 v-show="tipoFormulario == 'Solicitud'">Solicita tus vacaciones</h2>
                </div>

                <div class="modal-body">
                    <div class="divTable" style="padding-bottom: 20px">
                        <!-- v-show="tipoFormulario == 'Solicitud'"  -->
                        <div class="divTableBody">
                            <div class="divTableRow">
                                <div class="divTableCell divTableCellCenter">
                                    Días disponibles hasta el 31 de enero:
                                    {{ diasPendientes }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <form :id="tipoFormulario">
                        <div class="divTable">
                            <div class="divTableBody">
                                <div class="divTableRow" v-show="tipoFormulario == 'Aprobacion'">
                                    <div class="divTableCell">
                                        <label>Solicitante</label>
                                    </div>
                                    <div class="divTableCell">
                                        <input type="text" disabled v-model="Datos.Peticionario" />
                                    </div>
                                </div>
                                <!-- <div class="divTableRow">
                                        <div class="divTableCell"><label class="required">Tipo</label></div>
                                        <div class="divTableCell">
                                            <select v-model="Datos.TipoVacaciones" required>
                                                <option value="" disabled>Seleccionar tipo</option>
                                                <template v-for="tipo in tiposVacaciones" >
                                                    <option
                                                        :key="tipo"
                                                        :value="tipo">
                                                            {{tipo}}
                                                    </option>
                                                </template>
                                            </select>
                                        </div>
                                    </div> -->
                                <div class="divTableRow">
                                    <div class="divTableCell" style="width: 20%">
                                        <label class="required">Fecha de inicio</label>
                                    </div>
                                    <div class="divTableCell">
                                        <input
                                            id="txtFechaInicio"
                                            type="date"
                                            v-model="Datos.FechaInicio"
                                            :required="tipoFormulario == 'Solicitud'"
                                            :disabled="tipoFormulario == 'Aprobacion'"
                                            @change="changeFechaInicio"
                                        />
                                        <!-- <datepicker v-model="Datos.FechaInicio" :language="es" monday-first typeable format="dd/MM/yyyy"></datepicker> -->
                                    </div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">
                                        <label class="required">Fecha fin</label>
                                    </div>
                                    <div class="divTableCell">
                                        <input
                                            id="txtFechaFin"
                                            type="date"
                                            v-model="Datos.FechaFin"
                                            :required="tipoFormulario == 'Solicitud'"
                                            :disabled="tipoFormulario == 'Aprobacion'"
                                            @change="calcularDias(false)"
                                        />
                                        <!-- <datepicker v-model="Datos.FechaFin" :language="es" monday-first typeable format="dd/MM/yyyy"></datepicker> -->
                                    </div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">
                                        <label>D&iacute;as</label>
                                    </div>
                                    <div class="divTableCell">
                                        <input id="txtNumDias" type="number" v-model="Datos.DIAS" style="width: 50px" />
                                    </div>
                                </div>
                                <div class="divTableRow">
                                    <div class="divTableCell">
                                        <label>Comentario</label>
                                    </div>
                                    <div class="divTableCell">
                                        <textarea id="txtComentarios" v-model="Datos.Comentarios" :disabled="tipoFormulario == 'Aprobacion'" />
                                    </div>
                                </div>
                                <div class="divTableRow" v-show="tipoFormulario == 'Aprobacion'">
                                    <div class="divTableCell">
                                        <label>Motivo rechazo</label>
                                    </div>
                                    <div class="divTableCell">
                                        <textarea id="txtComentarios" v-model="Datos.MotivoRechazo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="modal-footer" v-show="tipoFormulario == 'Solicitud'">
                    <button class="modal-default-button" @click.prevent="close">Cancelar</button>
                    <button class="modal-default-button" @click.prevent="requestAbsence">Solicitar</button>
                </div>

                <div class="modal-footer" v-show="tipoFormulario == 'Aprobacion'">
                    <button class="modal-default-button" @click.prevent="close">Cancelar</button>
                    <button class="modal-default-button" @click.prevent="CheckForm(tipoFormulario, 'aprobar')">Aprobar</button>
                    <button class="modal-default-button" @click.prevent="CheckForm(tipoFormulario, 'denegar')">Denegar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//var Holidays = require("date-holidays");
import moment from "moment";
import { Holidays, APIs } from "../../common/constantes.js";
import { useToast } from "vue-toastification";

export default {
    name: "ModalVacaciones",
    props: ["visible", "tipoFormulario", "datosPrecargados", "diasPendientes", "oldPetitions"],
    data() {
        return {
            Datos: {
                USUARIOId: "",
                Peticionario: "",
                FechaInicio: "",
                FechaFin: "",
                DIAS: "",
                Comentarios: "",
                MotivoRechazo: "",
            },
            toast: useToast(),
            dateConflict: false,
        };
    },
    methods: {
        changeFechaInicio() {
            this.calcularDias(true);
        },
        close() {
            this.limpiarDatos();
            this.$emit("visibleChild", !this.visible);
        },
        solicitar() {
            this.habilitarFooter(false);
            this.GuardarSolicitudVacaciones().then(() => {
                this.$emit("nuevaSolicitud", this.Datos);
                this.close();
            });
        },
        aprobar() {
            this.habilitarFooter(false);
            this.$emit("aprobarDenegar", {
                datos: this.Datos,
                accion: "Aprobado",
            });
        },
        denegar() {
            this.habilitarFooter(false);
            this.$emit("aprobarDenegar", {
                datos: this.Datos,
                accion: "Rechazado",
            });
        },
        habilitarFooter(habilitar) {
            let footer = document.getElementsByClassName("modal-footer");
            for (let i = 0; i < footer.length; i++) {
                if (habilitar) {
                    footer[i].className = footer[i].className.replace("disabled", "");
                } else {
                    footer[i].className += " disabled";
                }
            }
        },
        limpiarDatos() {
            this.Datos.USUARIOId = "";
            this.Datos.Peticionario = "";
            this.Datos.FechaInicio = "";
            this.Datos.FechaFin = "";
            this.Datos.DIAS = "";
            this.Datos.Comentarios = "";
            this.Datos.MotivoRechazo = "";
            this.dateConflict = false;
            this.habilitarFooter(true);
        },
        GuardarSolicitudVacaciones: async function () {
            /**var self = this;
            return new Promise((resolve, reject) => {
                if (self.currentUser.Id != undefined && self.currentUser.Id != -1 )
                {
                     self.sp.web.lists.getByTitle(constantes.ListasSharepoint.Vacaciones).items.add({
                        [constantes.Columnas.Vacaciones.UsuarioId]: self.currentUser.Id,
                        [constantes.Columnas.Vacaciones.FechaInicio]: self.Datos.FechaInicio,
                        [constantes.Columnas.Vacaciones.FechaFin]: self.Datos.FechaFin,
                        [constantes.Columnas.Vacaciones.Comentarios]: self.Datos.Comentarios,
                        [constantes.Columnas.Vacaciones.NumeroDias]: self.Datos.DIAS,
                        [constantes.Columnas.Vacaciones.Estado]: "Pendiente"
                    }).then(i => {
                         self.$parent.$emit("datosNotificacion", {'show':true, 'tipo': 'success', 'mensaje':'Se ha guardado correctamente.'});
                        resolve(i);
                    }).catch(error=>{
                        //console.log(error);
                       self.$parent.$emit("datosNotificacion", {'show':true, 'tipo': 'error', 'mensaje':'Se ha producido un error.'});
                       self.habilitarFooter(true);
                        reject(error);
                    });
                }
                else {
                     self.$parent.$emit("datosNotificacion", {'show':true, 'tipo': 'error', 'mensaje':'Se ha producido un error. No se ha encontrado el empleado.'});
                     self.habilitarFooter(true);
                    reject('KO');
                }
            });**/
        },
        formatDate: (value, format) => {
            return moment(value).format(format);
        },
        isHoliday: (date) => {
            let month = date.getMonth();
            let day = date.getDate();

                if (Holidays[month].find((x) => x === day)) {
                    return true;
                }
                return false;
            },
            isWeekend: (date) => {
                return date.getDay() === 6 || date.getDay() === 0;
            },
            calcularDias: function (modifiedStartDate) {
                if (this.Datos.FechaFin === "" || this.Datos.FechaInicio === "") {
                    return;
                }

            if (this.Datos.FechaInicio != null && this.Datos.FechaFin != null) {
                let fecha1 = moment(this.Datos.FechaInicio);
                let fecha2 = moment(this.Datos.FechaFin);
                this.Datos.DIAS = 0;
                let fechas = this.enumerateDaysBetweenDates(fecha1, fecha2);

                fechas.forEach((fecha) => {
                    if (!this.isWeekend(fecha) && !this.isHoliday(fecha)) {
                        this.Datos.DIAS++;
                    }
                });
                // this.Datos.DIAS = fechas.length;
            } else {
                this.Datos.DIAS = 0;
            }
            this.checkDatesAreValid(modifiedStartDate);
        },
        checkDatesAreValid: function (modifiedStartDate) {
            let startDate = moment(this.Datos.FechaInicio);
            let endDate = moment(this.Datos.FechaFin);
            let now = moment();
            this.dateConflict = false;
            if (endDate.isBefore(startDate)) {
                if (modifiedStartDate) this.Datos.FechaInicio = "";
                else this.Datos.FechaFin = "";
                this.toast.error("La fecha de finalización no puede ser anterior a la fecha de inicio.");
                return;
            }

            if (
                startDate.isAfter(moment(`31-1-${now.year() + 1}`, "DD-MM-YYYY")) ||
                endDate.isAfter(moment(`31-1-${now.year() + 1}`, "DD-MM-YYYY"))
            ) {
                this.toast.error("Las vacaciones no pueden exceder el 31 de enero de " + (now.year() + 1));
                if (startDate.isAfter(moment(`31-1-${now.year() + 1}`, "DD-MM-YYYY"))) this.Datos.FechaInicio = "";
                if (endDate.isAfter(moment(`31-1-${now.year() + 1}`, "DD-MM-YYYY"))) this.Datos.FechaFin = "";
                return;
            }
            if (startDate.isSameOrBefore(now) || endDate.isSameOrBefore(now)) {
                this.toast.error("La fecha de inicio debe ser superior a la fecha de hoy.");
                if (startDate.isSameOrBefore(now)) this.Datos.FechaInicio = "";
                if (endDate.isSameOrBefore(now)) this.Datos.FechaFin = "";
                return;
            }

            this.checkNoDatesConflict();
        },
        checkNoDatesConflict: function () {
            this.dateConflict = false;
            let oldPetitions = this.oldPetitions.filter((row) => row.status == "ACCEPTED" || row.status == "PENDING");
            if (oldPetitions) {
                oldPetitions.forEach((oldPetition) => {
                    let oldStartDate = moment(oldPetition.startDate);
                    let oldEndDate = moment(oldPetition.endDate);
                    let newStartDate = moment(this.Datos.FechaInicio);
                    let newEndDate = moment(this.Datos.FechaFin);
                    if (
                        (newStartDate.isSameOrAfter(oldStartDate) && newStartDate.isSameOrBefore(oldEndDate)) ||
                        (newEndDate.isSameOrAfter(oldStartDate) && newEndDate.isSameOrBefore(oldEndDate)) ||
                        (newStartDate.isSameOrBefore(oldStartDate) && newEndDate.isSameOrAfter(oldEndDate))
                    ) {
                        this.toast.error(
                            "No puedes realizar esta solicitud ya que entra en conflicto con el siguiente periodo vacacional: " +
                                oldStartDate.format("DD-MM-YYYY") +
                                " al " +
                                oldEndDate.format("DD-MM-YYYY"),
                            {
                                timeout: 5000,
                            }
                        );
                        this.dateConflict = true;
                        return true;
                    }
                });
            }
            return false;
        },
        enumerateDaysBetweenDates: (startDate, endDate) => {
            var dates = [];
            var currDate = moment(startDate).startOf("day");
            var lastDate = moment(endDate).startOf("day");

            while (currDate.diff(lastDate) <= 0) {
                dates.push(currDate.clone().toDate());
                currDate.add(1, "days");
            }
            return dates;
        },
        requestAbsence: async function () {
            if (this.dateConflict) {
                if (this.checkNoDatesConflict()) return;
            }
            if (this.diasPendientes - this.Datos.DIAS < 0) {
                this.toast.error("No puedes solicitar más días que los que tienes disponibles.", {
                    timeout: 3000,
                });
                return;
            }
            if (this.Datos.FechaInicio == "" || this.Datos.FechaFin == "") {
                this.toast.error("No puedes realizar una solicitud sin rellenar los datos necesarios.", {
                    timeout: 3000,
                });
                return;
            }
            this.$isLoading(true);
            await this.apiQuery(APIs.holidaysRequest, {
                method: "POST",
                mode: "cors",
                headers: {
                    Authorization: "Bearer " + this.authToken,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    year: moment(this.Datos.FechaInicio).year(),
                    idApplicant: this.user,
                    absenceReason: "Holidays", //Por el momento solo vacaciones
                    startDate: moment(this.Datos.FechaInicio).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z",
                    endDate: moment(this.Datos.FechaFin).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z",
                    totalDays: this.Datos.DIAS,
                    comments: this.Datos.Comentarios,
                }),
            })
                .then((response) => {
                    if (response.ok && response.status == 200) {
                        this.$emit("visibleChild", false);
                        this.$emit("addNewPetition", {
                            year: 2022,
                            absenceReason: "Holidays",
                            startDate: this.Datos.FechaInicio,
                            endDate: this.Datos.FechaFin,
                            totalDays: this.Datos.DIAS,
                            comments: this.Datos.Comentarios,
                            status: "PENDING",
                        });
                        this.toast.success("Solicitud realizada correctamente.", {
                            timeout: 3000,
                        });
                    } else {
                        this.$emit("visibleChild", false);
                        this.toast.error("Ha ocurrido un error con tu solicitud, intentalo de nuevo más tarde, Error: " + response.status, {
                            timeout: 3000,
                        });
                    }
                })
                .catch(() => {
                    this.$emit("visibleChild", false);
                    this.toast.error("Ha ocurrido un error inesperado con tu solicitud, intentalo de nuevo más tarde.", {
                        timeout: 3000,
                    });
                });
            this.limpiarDatos();
            this.$isLoading(false);
        },
        Guardar: function (isValid, formId, accion) {
            if (isValid) {
                if (formId == "Solicitud") {
                    this.solicitar();
                } else if (formId == "Aprobacion") {
                    if (accion == "aprobar") {
                        this.aprobar();
                    } else if (accion == "denegar") {
                        this.denegar();
                    }
                }
            }
        },
        CheckElement: (element) => {
            if (element.required && !element.value.trim()) {
                return false;
            }
            if (element.value.trim() && !element.validity.valid) {
                return false;
            }
            return true;
        },
        compareDates: (from, to) => {
            //Generate an array where the first element is the year, second is month and third is day
            var splitFrom = from.split("/");
            var splitTo = to.split("/");

            //Create a date object from the arrays
            var fromDate = Date.parse(splitFrom[0], splitFrom[1] - 1, splitFrom[2]);
            var toDate = Date.parse(splitTo[0], splitTo[1] - 1, splitTo[2]);

            //Return the result of the comparison
            return fromDate <= toDate;
        },
        compareRangeDates: function (startA, endA, startB, endB) {
            let dtStartA = this.formatDate(new Date(startA), "YYYY-MM-DD");
            let dtEndA = this.formatDate(new Date(endA), "YYYY-MM-DD");
            let dtStartB = this.formatDate(new Date(startB), "YYYY-MM-DD");
            let dtendB = this.formatDate(new Date(endB), "YYYY-MM-DD");
            // startA                          endA
            // v                                        v
            // #----------------------------------------#
            //
            //         #----------------------#
            //         ^                      ^
            //         startB              endB
            return (dtStartB >= dtStartA && dtStartB <= dtEndA) || (dtStartA >= dtStartB && dtStartA <= dtendB);
        },
        _getVacaciones: async function (/**yearStart, yearEnd**/) {
            /**var self = this;
                return new Promise((resolve, reject) => {
                    self.sp.web.lists
                        .getByTitle(constantes.ListasSharepoint.Vacaciones)
                        .items.filter(
                            `${constantes.Columnas.Vacaciones.UsuarioId} eq ${self.currentUser.Id} and
                        ${constantes.Columnas.Vacaciones.FechaFin} ge '${yearStart}-01-01T00:00:00Z' and
                        ${constantes.Columnas.Vacaciones.FechaFin} le '${yearEnd}-12-31T00:00:00Z' and
                        ${constantes.Columnas.Vacaciones.Estado} ne 'Rechazado'`
                        )
                        .get()
                        .then((response) => {
                            resolve(response);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                });**/
        },
        _getDiasPendientes: async function () {
            /**var self = this;
                return new Promise((resolve, reject) => {
                    self.sp.web.lists
                        .getByTitle(constantes.ListasSharepoint.DiasPendientes)
                        .items.filter(
                            `${constantes.Columnas.DiasPendientes.UsuarioId} eq ${self.Datos.USUARIOId}`
                        )
                        .get()
                        .then((response) => {
                            if (response.length > 0) {
                                resolve(
                                    response[0][
                                        constantes.Columnas.DiasPendientes
                                            .DiasPendientes
                                    ]
                                );
                            } else resolve(0);
                        })
                        .catch((error) => {
                            reject(0);
                        });
                });**/
        },
    },
    watch: {
        "Datos.DIAS": function (newDays) {
            if (newDays < 0) {
                this.Datos.DIAS = 0;
            }
        },
    },
    computed: {
        tiposVacaciones() {
            return "store.state.tiposVacaciones";
        },
        authToken: function () {
            return this.$store.getters.getToken;
        },
        user: function () {
            return this.$store.getters.getUser;
        },
    },
    async updated() {
        if (this.visible) {
            if (this.datosPrecargados) {
                this.Datos = this.datosPrecargados;
            }
            if (this.tipoFormulario == "Aprobacion") {
                //this.diasPendientes = await this._getDiasPendientes();
            }
        }
    },
};
</script>
