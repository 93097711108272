<template>
    <div id="AprobacionVacacionesComponent" v-show="visible">
        <h1 class="cabecera">Aprobación de vacaciones</h1>
        <hr />
        <div class="divTable">
            <div class="divTableBody">
                <div class="divTableRow">
                    <div class="divTableCell">
                        Buscador
                        <input name="query" v-model="searchQueryAprobaciones" />
                    </div>
                    <div class="divTableCell divTableCellRight">
                        <div @click="ObtenerDatos" title="Actualizar datos" class="botonRefresh">
                            <i class="fa-solid fa-arrows-rotate customColorBlue"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showGridAprobaciones">
            <table class="customGrid">
                <thead>
                    <tr>
                        <th style="width: 325px" @click="sort('userName')">
                            <SortingTableHeader :activeOrder="activeOrder" :order="order" :column="'userName'" :title="'Peticionario'" />
                        </th>
                        <th style="width: 15px" @click="sort('days')">
                            <SortingTableHeader :activeOrder="activeOrder" :order="order" :column="'days'" :title="'Días'" />
                        </th>
                        <th style="width: 130px; text-align: center" @click="sort('dateStart')">
                            <SortingTableHeader :activeOrder="activeOrder" :order="order" :column="'dateStart'" :title="'Fecha inicio'" />
                        </th>
                        <th style="width: 130px; text-align: center" @click="sort('dateEnd')">
                            <SortingTableHeader :activeOrder="activeOrder" :order="order" :column="'dateEnd'" :title="'Fecha fin'" />
                        </th>
                        <th @click="sort('comments')">
                            <SortingTableHeader :activeOrder="activeOrder" :order="order" :column="'comments'" :title="'Comentarios'" />
                        </th>
                        <th style="width: 10px"></th>
                    </tr>
                </thead>
                <tbody v-if="!loading && (searchQueryAprobaciones == '' ? DatosAprobaciones : filteredDatosAprobaciones).length > 0">
                    <tr v-for="datum in searchQueryAprobaciones == '' ? DatosAprobaciones : filteredDatosAprobaciones" :key="datum.id">
                        <td
                            @click="
                                oneActionId = datum.id;
                                showTakeOneActionModal = true;
                            "
                            class="linkStyle"
                        >
                            {{ datum.userName ? datum.userName : "Nombre no encontrado" }}
                        </td>
                        <td
                            style="text-align: right"
                            @click="
                                oneActionId = datum.id;
                                showTakeOneActionModal = true;
                            "
                        >
                            {{ datum.days }}
                        </td>
                        <td
                            style="text-align: center"
                            @click="
                                oneActionId = datum.id;
                                showTakeOneActionModal = true;
                            "
                        >
                            {{ formatDate(datum.dateStart, "DD-MM-YYYY") }}
                        </td>
                        <td
                            style="text-align: center"
                            @click="
                                oneActionId = datum.id;
                                showTakeOneActionModal = true;
                            "
                        >
                            {{ formatDate(datum.dateEnd, "DD-MM-YYYY") }}
                        </td>
                        <td
                            @click="
                                oneActionId = datum.id;
                                showTakeOneActionModal = true;
                            "
                        >
                            {{ datum.comments }}
                        </td>
                        <td>
                            <input type="checkbox" v-model="selection[datum.id]" />
                        </td>
                    </tr>
                </tbody>
                <tbody v-if="loading">
                    <div style="padding: 10px">Cargando ...</div>
                </tbody>
                <tbody v-if="!loading && (searchQueryAprobaciones == '' ? DatosAprobaciones : filteredDatosAprobaciones).length == 0">
                    <div style="padding: 10px">
                        {{
                            searchQueryAprobaciones == ""
                                ? "No se han encontrado peticiones pendientes de aprobación"
                                : "No se han encontrado peticiones pendientes de aprobación con los parámetros sugeridos"
                        }}
                    </div>
                </tbody>
            </table>
        </div>
        <div>
            <RejectionModal
                :visible="showRejectionModal"
                v-on:accept="completeRejection"
                v-on:cancel="showRejectionModal = false"
                :rejected="rejectModalData.rejected"
                :totalToReject="rejectModalData.totalToReject"
            />
            <ApprovalModal
                :visible="showApprovalModal"
                v-on:accept="completeApproval"
                v-on:cancel="showApprovalModal = false"
                :approved="approvalModalData.approved"
                :totalToApprove="approvalModalData.totalToApprove"
            />
            <TakeActionOnOne
                :visible="showTakeOneActionModal"
                :actionData="DatosAprobaciones.find((row) => row.id == oneActionId)"
                v-if="oneActionId != -1"
                v-on:accept="approveOne"
                v-on:reject="rejectOne"
                v-on:cancel="showTakeOneActionModal = false"
            />
        </div>
        <div>
            <modal-result-aprobacion
                :visible="showModalResultAprobaciones"
                :datos="DatosResultAprobacion"
                v-on:visibleChild="OpenCloseModalResultAprobaciones"
            >
            </modal-result-aprobacion>
        </div>
        <input type="button" value="Aprobar" @click="AprobarDenegarMultiple('approve')" class="modal-default-button" v-show="!loading" />
        <!--<button
            class="modal-default-button modal-default-button-disabled"
            v-show="loading"
        >
            <i class="fas fa-spinner fa-spin fa-5x"></i>
            Loading
        </button>-->
        <input type="button" value="Denegar" @click="AprobarDenegarMultiple('reject')" class="modal-default-button" v-show="!loading" />
    </div>
</template>
<script>
    import ModalResultAprobacion from "./ModalResultAprobacion.vue";
    import ApprovalModal from "./ApprovalModal.vue";
    import RejectionModal from "./RejectionModal.vue";
    import TakeActionOnOne from "./TakeActionOnOne.vue";
    import SortingTableHeader from "../SortingTableHeader.vue";
    import { APIs, menuIds } from "../../common/constantes.js";
    import moment from "moment";

    const menuId = menuIds.holidaysApprove;

    export default {
        name: "AprobacionVacaciones",
        props: ["visible"],
        components: {
            ModalResultAprobacion,
            RejectionModal,
            ApprovalModal,
            TakeActionOnOne,
            SortingTableHeader,
        },
        data() {
            return {
                showGridAprobaciones: true,
                searchQueryAprobaciones: "",
                customCalendar: {
                    month: new Date().getMonth(),
                },
                DatosAprobaciones: [],
                showModalAprobaciones: false,
                showRejectionModal: false,
                showApprovalModal: false,
                showTakeOneActionModal: false,
                oneActionId: -1,
                approvalModalData: {
                    totalToApprove: -1,
                    approved: 0,
                },
                rejectModalData: {
                    totalToReject: -1,
                    rejected: 0,
                },
                DatoAprobacion: {
                    Id: "",
                    USUARIOId: "",

                    Peticionario: "",
                    FechaInicio: "",
                    FechaFin: "",
                    DIAS: "",
                    Comentarios: "",
                },
                showModalResultAprobaciones: false,
                DatosResultAprobacion: [],
                selection: {},
                loading: false,
                order: {},
                activeOrder: "dateStart",
            };
        },
        computed: {
            authToken: function () {
                return this.$store.getters.getToken;
            },
            user: function () {
                return this.$store.getters.getUser;
            },
            customCalendarMonthLabel: function () {
                switch (this.customCalendar.month) {
                    case 0:
                        return "Enero";
                    case 1:
                        return "Febrero";
                    case 2:
                        return "Marzo";
                    case 3:
                        return "Abril";
                    case 4:
                        return "Mayo";
                    case 5:
                        return "Junio";
                    case 6:
                        return "Julio";
                    case 7:
                        return "Agosto";
                    case 8:
                        return "Septiembre";
                    case 9:
                        return "Octubre";
                    case 10:
                        return "Noviembre";
                    case 11:
                        return "Diciembre";
                }
                return "";
            },
            filteredDatosAprobaciones: function () {
                return this.DatosAprobaciones.filter((row) => {
                    if (row.userName.includes(this.searchQueryAprobaciones)) {
                        return row;
                    }
                });
            },
            contentShowed: function () {
                return this.$store.getters.getVisibleMenu;
            },
        },
        watch: {
            visible(newVal) {
                if (newVal) {
                    this.ObtenerDatos();
                }
            },
            "customCalendar.month"(newMonth) {
                if (newMonth > 11) {
                    this.customCalendar.month = 0;
                } else if (newMonth < 0) {
                    this.customCalendar.month = 1;
                }
            },
            loading: function (newLoading) {
                if (this.contentShowed == menuId) {
                    this.$isLoading(newLoading);
                    this.$emit("resized");
                }
            },
            contentShowed: function (newContent) {
                if (newContent == menuId) {
                    this.$isLoading(this.loading);
                }
            },
        },
        methods: {
            sort: function (column) {
                if (this.DatosAprobaciones.length == 0) {
                    return;
                }
                if (this.order[column] == undefined) {
                    this.order[column] = false;
                }
                if (column.includes("date")) {
                    if (this.order[column]) {
                        this.DatosAprobaciones = this.DatosAprobaciones.sort((a, b) => moment(a[column]).unix() - moment(b[column]).unix());
                    } else {
                        this.DatosAprobaciones = this.DatosAprobaciones.sort((a, b) => moment(b[column]).unix() - moment(a[column]).unix());
                    }
                } else {
                    if (typeof this.DatosAprobaciones[0][column] == "string")
                        this.DatosAprobaciones = this.DatosAprobaciones.sort((a, b) => {
                            return ("" + a[column]).localeCompare(b[column]);
                        });
                    else this.DatosAprobaciones = this.DatosAprobaciones.sort((a, b) => a[column] - b[column]);
                    if (!this.order[column]) this.DatosAprobaciones = this.DatosAprobaciones.reverse();
                }
                this.order[column] = !this.order[column];
                this.activeOrder = column;
            },
            OpenCloseModalAprobaciones: function (value) {
                this.showModalAprobaciones = value;
                if (!value) {
                    this.ObtenerDatos();
                }
            },
            OpenCloseModalResultAprobaciones: function (value) {
                this.showModalResultAprobaciones = value;
            },
            ObtenerDatos: function () {
                this.loading = true;
                this.DatosAprobaciones = [];
                this.order = {};
                this.activeOrder = "dateStart";
                this._getAprobaciones();
            },
            _getAprobaciones: async function () {
                return new Promise((resolve, reject) => {
                    this.apiQuery(APIs.getAbsencesRepresentative, {
                        method: "POST",
                        mode: "cors",
                        headers: {
                            Authorization: "Bearer " + this.authToken,
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            dnirepresentative: this.user,
                            status :"PENDING",
                        }),
                    })
                        .then((response) => response.json())
                        .then((_result) => {
                            this.DatosAprobaciones = [];

                            let result = _result;//.filter((row) => row.status == "");
                            this.$store.commit("setResponsableNotifications", result.length);
                            result.forEach((element) => {
                                this.DatosAprobaciones.push({
                                    id: element.id,
                                    petitioner: element.idApplicant,
                                    userName: element.userName,
                                    dateStart: moment(element.startDate),
                                    dateEnd: moment(element.endDate),
                                    comments: element.comments,
                                    days: element.totalDays,
                                });
                            });
                            this.DatosAprobaciones = this.DatosAprobaciones.sort((a, b) => a.dateStart.unix() - b.dateStart.unix());
                            this.loading = false;
                            resolve(result);
                        })
                        .catch((error) => reject(error));
                });
            },
            AprobarDenegarVacaciones: function (data) {
                this.ActualizarAprobaciones(data.datos, data.accion).then(() => {
                    this.OpenCloseModalAprobaciones(false);
                });
            },

            getDiasPendientes: async function (dni) {
                return new Promise((resolve, reject) => {
                    this.apiQuery(APIs.getDaysData + "?dni=" + dni, {
                        method: "GET",
                        mode: "cors",
                        headers: {
                            Authorization: "Bearer " + this.authToken,
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        },
                    })
                        .then((response) => response.json())
                        .then((result) => {
                            resolve(result.total - result.taken);
                        })
                        .catch(() => {
                            reject(null);
                        });
                });
            },

            AprobarDenegarMultiple: async function (accion) {
                if (accion == "reject") {
                    this.showRejectionModal = true;
                } else if (accion == "approve") {
                    this.showApprovalModal = true;
                }
            },
            formatDate: (value, format) => {
                return moment(value).format(format);
            },
            completeRejection: async function (comment) {
                this.showRejectionModal = false;

                let datosSeleccionados = this.getSelectedData();

                let _DatosResultAprobacion = [];
                this.rejectModalData.totalToReject = datosSeleccionados.length;
                this.loading = true;
                await Promise.all(
                    datosSeleccionados.map(async (datum) => {
                        let updatedDatum = Object.assign({}, datum);

                        let result = await this.apiQuery(APIs.rejectAbsence, {
                            method: "POST",
                            mode: "cors",
                            headers: {
                                Authorization: "Bearer " + this.authToken,
                                Accept: "application/json",
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                id: datum.id,
                                reasonRejectCancel: comment,
                            }),
                        });

                        if (result.ok && result.status == 202) updatedDatum.RESULT = "Rechazado Correctamente";
                        else updatedDatum.RESULT = "No se ha podido rechazar la solicitud";
                        updatedDatum.REVISION_COMMENT = comment;
                        _DatosResultAprobacion.push(updatedDatum);
                        this.rejectModalData.rejected++;
                    })
                );
                this.loading = false;
                this.DatosResultAprobacion = _DatosResultAprobacion;
                this.showModalResultAprobaciones = true;

                this.rejectModalData.totalToReject = -1;
                this.rejectModalData.rejected = 0;

                this.ObtenerDatos();
            },
            completeApproval: async function (comment) {
                this.showApprovalModal = false;

                let datosSeleccionados = this.getSelectedData();

                let _DatosResultAprobacion = [];
                this.approvalModalData.pendingToApprove = datosSeleccionados.length;

                this.loading = true;
                await Promise.all(
                    datosSeleccionados.map(async (datum) => {
                        let updatedDatum = Object.assign({}, datum);
                        let daysRemaining = await this.getDiasPendientes(datum.petitioner);

                        if (!daysRemaining) {
                            updatedDatum.RESULT = "No se han podido comprobar los días de vacaciones restantes, No aprobada";
                        } else if (daysRemaining >= 0) {
                            let response = await this.apiQuery(APIs.approveAbsence, {
                                method: "POST",
                                mode: "cors",
                                headers: {
                                    Authorization: "Bearer " + this.authToken,
                                    Accept: "application/json",
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify({
                                    id: datum.id,
                                    allTotalDays: datum.days,
                                    comments: comment,
                                }),
                            });

                            if (response.ok && response.status == 202) updatedDatum.RESULT = "Aprobado Correctamente";
                            else updatedDatum.RESULT = "No se a podido aprobar debido a un error inesperado";
                        } else {
                            updatedDatum.RESULT = "No se ha podido aprobar porque el usuario no tenía suficientes días restantes";
                        }

                        updatedDatum.REVISION_COMMENT = comment;
                        _DatosResultAprobacion.push(updatedDatum);

                        this.approvalModalData.approved++;
                    })
                );
                this.loading = false;
                this.DatosResultAprobacion = _DatosResultAprobacion;
                this.showModalResultAprobaciones = true;

                this.approvalModalData.totalToApprove = -1;
                this.approvalModalData.approved = 0;

                this.ObtenerDatos();
            },
            getSelectedData: function () {
                return this.DatosAprobaciones.filter((row) => {
                    if (this.selection[row.id] == true) {
                        return row;
                    }
                });
            },
            approveOne: async function (comment, modifiedDays) {
                this.showTakeOneActionModal = false;

                let _DatosResultAprobacion = [];

                this.loading = true;

                let datum = this.DatosAprobaciones.find((row) => row.id == this.oneActionId);

                let updatedDatum = Object.assign({}, datum);
                let daysRemaining = await this.getDiasPendientes(datum.petitioner);

                if (!daysRemaining) {
                    updatedDatum.RESULT = "No se han podido comprobar los días de vacaciones restantes, No aprobada";
                } else if (daysRemaining >= 0) {
                    let response = await this.apiQuery(APIs.approveAbsence, {
                        method: "POST",
                        mode: "cors",
                        headers: {
                            Authorization: "Bearer " + this.authToken,
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            id: datum.id,
                            allTotalDays: datum.days,
                            newTotalDays: modifiedDays,
                            comments: comment,
                        }),
                    });

                    updatedDatum.days = modifiedDays;

                    if (response.ok && response.status == 202) updatedDatum.RESULT = "Aprobado Correctamente";
                    else updatedDatum.RESULT = "No se a podido aprobar debido a un error inesperado";
                } else {
                    updatedDatum.RESULT = "No se ha podido aprobar porque el usuario no tenía suficientes días restantes";
                }

                updatedDatum.REVISION_COMMENT = comment;
                _DatosResultAprobacion.push(updatedDatum);

                this.loading = false;
                this.DatosResultAprobacion = _DatosResultAprobacion;
                this.showModalResultAprobaciones = true;

                this.oneActionId = -1;

                this.ObtenerDatos();
            },
            rejectOne: async function (comment) {
                this.showTakeOneActionModal = false;

                let _DatosResultAprobacion = [];

                this.loading = true;

                let datum = this.DatosAprobaciones.find((row) => row.id == this.oneActionId);

                let updatedDatum = Object.assign({}, datum);

                let result = await this.apiQuery(APIs.rejectAbsence, {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        Authorization: "Bearer " + this.authToken,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        id: datum.id,
                        reasonRejectCancel: comment,
                    }),
                });

                if (result.ok && result.status == 202) updatedDatum.RESULT = "Rechazado Correctamente";
                else updatedDatum.RESULT = "No se ha podido rechazar la solicitud";
                updatedDatum.REVISION_COMMENT = comment;
                _DatosResultAprobacion.push(updatedDatum);

                this.loading = false;
                this.DatosResultAprobacion = _DatosResultAprobacion;
                this.showModalResultAprobaciones = true;

                this.oneActionId = -1;

                this.ObtenerDatos();
            },
        },
        // created(){
        //     // En este componente se obtienen los datos al acceder a la app para obtener el numero de aprobaciones.
        //    let self = this;
        //     let checkCurrentUser = setInterval(() => {
        //     if (self.currentUser.Id != "" && self.currentUser.EsSupervisor != '') {
        //         self.ObtenerDatos();
        //         clearInterval(checkCurrentUser);
        //       }
        //     }, 100);
        // }
    };
</script>
<style scoped>
    .calendarButton {
        border-radius: 100%;
        height: 25px;
        width: 25px;
        border: 0px;
        color: white;
        background-color: #002e72;
        cursor: pointer;
        font-weight: bolder;
        margin-left: 15px;
        margin-right: 15px;
    }

    .calendarButton:hover {
        color: #002e72;
        background-color: white;
    }

    .calendarContainer {
        text-align: center;
    }

    .calendarMonth {
        width: 120px;
    }

    .linkStyle {
        cursor: pointer;
        text-decoration: underline;
        transition: all 0.3s ease;
    }

    .linkStyle:hover {
        font-weight: bold;
    }
</style>
