<template>
    <div class="modal-mask" v-show="visible">
        <div class="modal-wrapper">
            <div class="modal-container">
                <div class="modal-header" style="text-align: left">
                    <h2>Confirmación</h2>
                </div>
                <div class="modal-body">
                    <div class="divTable" style="padding: 24px">
                        <div class="divTableRow">
                            <div class="divTableCell divTableCellCenter" style="text-align: center">
                                <label>¿Estás seguro que deseas aprobar la solicitud?</label>
                            </div>
                        </div>
                    </div>
                    <div class="divTable">
                        <div class="divTableRow">
                            <div class="divTableCell">
                                <label class="required">Fecha de inicio</label>
                            </div>
                            <div class="divTableCell">
                                {{ moment(actionData.dateStart).format("DD-MM-YYYY") }}
                                <!-- <datepicker v-model="Datos.FechaInicio" :language="es" monday-first typeable format="dd/MM/yyyy"></datepicker> -->
                            </div>
                        </div>
                        <div class="divTableRow">
                            <div class="divTableCell">
                                <label class="required">Fecha fin</label>
                            </div>
                            <div class="divTableCell">
                                {{ moment(actionData.dateEnd).format("DD-MM-YYYY") }}
                                <!-- <datepicker v-model="Datos.FechaFin" :language="es" monday-first typeable format="dd/MM/yyyy"></datepicker> -->
                            </div>
                        </div>
                        <div class="divTableRow">
                            <div class="divTableCell">
                                <label>D&iacute;as</label>
                            </div>
                            <div class="divTableCell">
                                <input id="txtNumDias" type="number" v-model="modifiedDays" />
                            </div>
                        </div>
                        <div class="divTableRow">
                            <div class="divTableCell">
                                <label>Comentario</label>
                            </div>
                            <div class="divTableCell">
                                <p>{{ actionData?.comments }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="divTable">
                        <div class="divTableRow">
                            <div class="divTableCell">
                                <label>Comentario del supervisor:</label>
                            </div>
                        </div>
                        <div class="divTableRow">
                            <div class="divTableCell divTableCellCenter" style="text-align: center">
                                <textarea v-model="comment"></textarea>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <button class="modal-button accept" @click.prevent="accept">Sí, aprobar</button>
                    <button class="modal-button cancel" @click.prevent="reject">No, rechazar</button>
                    <button class="modal-button" @click.prevent="cancel">Cerrar</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { useToast } from "vue-toastification";
    import _moment from "moment";

    export default {
        name: "Confirmation Box",
        props: ["visible", "actionData"],
        data() {
            return {
                comment: "",
                toast: useToast(),
                modifiedDays: this.actionData.days,
            };
        },
        methods: {
            accept: function () {
                this.$emit("accept", this.comment, this.modifiedDays);
                this.comment = "";
            },
            cancel: function () {
                this.comment = "";
                this.$emit("cancel");
            },
            reject: function () {
                if (this.comment == "") {
                    this.toast.error("Debes añadir un motivo al rechazar la solicitud.", {
                        timeout: 3000,
                    });
                } else {
                    this.$emit("reject", this.comment);
                    this.comment = "";
                }
            },
            moment: function (...args) {
                return _moment(...args);
            },
        },
        watch: {
            "actionData.days": function (newDays) {
                this.modifiedDays = newDays;
            },
            modifiedDays: function (newDays) {
                if (newDays < 0) {
                    this.modifiedDays = 0;
                } /*else if (newDays > 30) {
                    this.modifiedDays = 30;
                }*/
            },
        },
    };
</script>
<style scoped>
    h4 {
        font-size: 15px;
    }

    h2 {
        margin-block-end: 0;
    }

    .modal-wrapper {
        color: black;
        text-align: center;
    }

    .modal-header {
        height: 75px;
    }

    .modal-container {
        width: 60%;
        height: 80%;
        min-height: 360px;
        min-width: 300px;
        top: 5%;
        left: calc(50% - max(120px, 60%/2));
    }

    .modal-body {
        height: 100%;
    }

    .accept {
        background-color: #27ae60;
        border-color: #27ae60;
        transition: all 0.3s;
    }

    .accept:hover {
        color: #ecf0f1;
    }

    .cancel {
        background-color: #c0392b;
        border-color: #c0392b;
        transition: all 0.3s;
    }

    .cancel:hover {
        color: #ecf0f1;
    }

    .divTableCell {
        text-align: left;
    }

    .alignLeft {
        text-align: left;
    }
</style>
