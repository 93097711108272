<template>
    <div class="modal-mask" v-show="visible">
        <div class="modal-wrapper">
            <div class="modal-container">
                <div class="modal-header">
                    <h1>Recibos de nómina</h1>
                </div>
                <form>
                    <div class="modal-body">
                        <form id="search">
                            Año
                            <select @change="ObtenerDatos" v-model="anyoSeleccionado">
                                <option v-for="year in years" :key="year" :value="year">
                                    {{ year }}
                                </option>
                            </select>
                        </form>
                        <div v-if="apiError == '' || apiError == null">
                            <!-- <CustomGrid visible="true" :columns="columnsNomina" :items="DatosNomina" :filter-key="searchQueryNomina" v-on:itemSeleccionado="DescargaNomina" :loading="loadingVisible">
                            </CustomGrid> -->
                            <table v-show="loaded == true && downloadingDocument == false && DatosNomina.length > 0" class="customGrid">
                                <thead>
                                    <tr>
                                        <th>Mes</th>
                                        <th>Empresa</th>
                                        <th v-show="!superSmallDevice">Subsidiaria</th>
                                        <th v-show="!smallDevice">Tipo</th>
                                        <th>Descarga</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="datum in DatosNomina" :key="datum.objId">
                                        <td>
                                            <a v-on:click="DescargaNomina(datum)">{{ datum.mes }}</a>
                                        </td>
                                        <td>{{ datum.empresa }}</td>
                                        <td v-show="!superSmallDevice">
                                            {{ datum.delegacion }}
                                        </td>
                                        <td v-show="!smallDevice">
                                            <a v-on:click="DescargaNomina(datum)">Nómina</a>
                                        </td>
                                        <td>
                                            <a v-on:click="DescargaNomina(datum)" aria-label="Descargar PDF"
                                                ><i class="fa-regular fa-file-pdf fa-2x" style="color: red" title="Descargar PDF"></i
                                            ></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <h3 v-show="loaded == true && DatosNomina.length == 0">No hay nóminas para este año</h3>
                            <h3 v-show="loaded == false"><i class="fas fa-spinner fa-spin"></i> Cargando nóminas...</h3>
                            <h3 v-show="downloadingDocument == true">
                                <i class="fas fa-spinner fa-spin"></i>
                                Descargando documento...
                            </h3>
                        </div>
                        <div v-if="apiError != '' && apiError != null">
                            <h3 style="color: red">
                                <i class="fa-solid fa-triangle-exclamation"></i>
                                {{ apiError }}
                            </h3>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="modal-default-button" @click.prevent="close">Cerrar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    // import { constant } from '@microsoft/sp-lodash-subset';
    import Constantes from "../common/constantes";
    const { APIs } = Constantes;
    import * as moment from "moment";
    import "moment/locale/es";

    export default {
        name: "ModalNominas",
        props: ["visible"],
        data() {
            return {
                searchQueryNomina: "",
                columnsNomina: [
                    {
                        nombre: "Mes de la paga",
                        id: "mes",
                        orden: "",
                        type: "linkSelector",
                    },
                    {
                        nombre: "Tipo",
                        id: "tipoDocumento",
                        orden: "",
                        type: "text",
                    },
                ],
                DatosNomina: [],
                anyoSeleccionado: new Date().getFullYear(),
                loaded: false,
                downloadingDocument: false,
                nominaSeleccionada: {},
                apiError: "",
                smallDevice: false,
                superSmallDevice: false,
            };
        },
        methods: {
            close() {
                this.$emit("visibleChild", !this.visible);
            },
            ObtenerDatos() {
                this.DatosNomina = [];
                this.GetNominas()
                    .then((datos) => {
                        datos
                            .sort((a, b) => {
                                return a.mes - b.mes;
                            })
                            .forEach((element) => {
                                this.DatosNomina.push({
                                    mes: moment(element.mes, "MM").format("MMMM"),
                                    anyo: element.anyo,
                                    tipoDocumento: element.tipoDocumento,
                                    objId: element.objId,
                                    empresa: element.sociedad,
                                    delegacion: element.delegacion,
                                    url: APIs.DescargaDocumentos + element.objId,
                                });
                            });
                        this.loaded = true;
                    })
                    .catch(() => (this.apiError = "Ha ocurrido un error, intentalo de nuevo más tarde."));
                /**var self = this;
             return new Promise((resolve) => {
                self.loadingVisible = true;
                self.DatosNomina = [];
                this.GetNominas().then(datos => {
                    datos.sort((a, b) => { return a.mes-b.mes; }).forEach(element => {
                        self.DatosNomina.push({
                            mes: moment(element.mes, 'MM').format('MMMM'),
                            anyo: element.anyo,
                            tipoDocumento: element.tipoDocumento,
                            objId: element.objId,
                            url: Constantes.APIs.DescargaDocumentos + "documentId=" + element.objId
                        });
                   resolve(self.DatosNomina);
                   self.loadingVisible = false;
                });
                })
                .catch(error => {
                    console.log(error);
                    self.loadingVisible = false;
                });
                
            });*/
            },
            GetNominas() {
                this.loaded = false;
                this.apiError = "";
                return new Promise((resolve, reject) => {
                    this.apiQuery(APIs.nominas + this.user + "&anyo=" + this.anyoSeleccionado, {
                        method: "GET",
                        mode: "cors",
                        headers: {
                            Authorization: "Bearer " + this.authToken,
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        },
                    })
                        .then(async (result) => {
                            if (result.status === 404) {
                                const reader = await result.text();
                                reject(JSON.parse(reader).message);
                            } else {
                                const reader = await result.text();
                                resolve(JSON.parse(reader));
                            }
                        })
                        .catch((error) => {
                            console.error("error:", error);
                            reject(error);
                        });
                });
            },
            DescargaNomina(data) {
                this.apiError = "";
                this.nominaSeleccionada = data;
                if (this.downloadingDocument || !this.loaded) {
                    return;
                }
                this.downloadingDocument = true;
                const self = this;
                this.apiQuery(data.url, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        Authorization: "Bearer " + this.authToken,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                })
                    .then((r) => r.blob())
                    .then(self.showFile)
                    .catch((error) => {
                        this.apiError = "Ha ocurrido un error, intentalo de nuevo más tarde.";
                        this.downloadingDocument = false;
                        console.error("error:", error);
                    });
            },
            showFile(blob) {
                // It is necessary to create a new blob object with mime-type explicitly set
                // otherwise only Chrome works like it should
                var newBlob = new Blob([blob], { type: "application/pdf" });

                // IE doesn't allow using a blob object directly as link href
                // instead it is necessary to use msSaveOrOpenBlob
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(newBlob);
                    this.downloadingDocument = false;
                    return;
                }

                // For other browsers:
                // Create a link pointing to the ObjectURL containing the blob.
                const data = window.URL.createObjectURL(newBlob);
                var link = document.createElement("a");
                link.target = "_blank";
                link.href = data;
                link.download = "Nomina_" + this.nominaSeleccionada.anyo + "_" + this.nominaSeleccionada.mes + ".pdf";
                link.click();
                setTimeout(() => {
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    window.URL.revokeObjectURL(data);
                }, 100);

                this.downloadingDocument = false;
            },
            resizeContent: function () {
                this.smallDevice = document.documentElement.clientWidth < 600;
                this.superSmallDevice = document.documentElement.clientWidth < 390;
            },
        },
        computed: {
            years: () => {
                let anyos = [];
                let currentYear = new Date().getFullYear();
                for (let i = currentYear; i > currentYear - 5; i--) {
                    anyos.push(i);
                }
                return anyos;
            },
            user() {
                return this.$store.getters.getUser;
            },
            authToken: function () {
                return this.$store.getters.getToken;
            },
        },
        watch: {
            visible(newVal) {
                if (newVal) {
                    this.ObtenerDatos();
                }
            },
        },
        created() {
            window.addEventListener("resize", this.resizeContent);
        },
        unmounted() {
            window.removeEventListener("resize", this.resizeContent);
        },
        mounted() {
            this.resizeContent();
        },
    };
</script>
