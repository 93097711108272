<template>
    <div v-show="visible">
        <div v-for="noticia in Noticias.sort((n1, n2) => n2.id - n1.id)" :key="noticia.id">
            <h1>{{ noticia.title }}</h1>
            <hr />
            <a v-if="noticia.link" :href="noticia.link" target="_blank" style="text-decoration: none">
                <div class="newsDisplay">
                    <img v-if="noticia.image" :src="noticia.image" class="imgCarousel" />
                    <p v-html="noticia.description" />
                </div>
            </a>
        </div>
    </div>
</template>

<script>
    import constantes from "../common/constantes";
    const { APIs } = constantes;

    export default {
        name: "News",
        props: ["visible"],
        data() {
            return {
                Noticias: [],
            };
        },
        mounted() {
            this.apiQuery(APIs.News, {
                method: "GET",
                mode: "cors",
                headers: {
                    Authorization: "Bearer " + this.authToken,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json())
                .then((result) => {
                    this.Noticias = result;
                })
                .catch((error) => console.log(error));
        },
        computed: {
            authToken: function () {
                return this.$store.getters.getToken;
            },
        },
    };
</script>
<style scoped>
    .imgCarousel {
        max-width: 300px;
    }
    .newsDisplay {
        text-align: center;
    }
    .newsDisplay > p {
        text-align: left;
    }
</style>
