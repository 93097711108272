<template>
    <carousel v-if="Noticias.length > 0" :items-to-show="1" class="caroussel">
        <slide v-for="noticia in Noticias" :key="noticia.id">
            <a v-if="noticia.link" :href="noticia.link" target="_blank" style="text-decoration: none">
                <div class="divTable">
                    <div class="divTableBody">
                        <div class="divTableRow">
                            <div class="divTableCell cellImgCarousel">
                                <img v-if="noticia.image" :src="noticia.image" class="imgCarousel" />
                                <i class="fa-solid fa-bullhorn fa-3x" v-else></i>
                            </div>
                            <div class="divTableCell">
                                <span v-html="noticia.description"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
            <div v-else class="divTable">
                <div class="divTableBody">
                    <div class="divTableRow">
                        <div class="divTableCell cellImgCarousel">
                            <img v-if="noticia.image" :src="noticia.image" class="imgCarousel" />
                            <i class="fa-solid fa-bullhorn fa-3x" v-else></i>
                        </div>
                        <div class="divTableCell">
                            <span v-html="noticia.description"></span>
                        </div>
                    </div>
                </div>
            </div>
        </slide>

        <template #addons>
            <pagination />
        </template>
    </carousel>
</template>

<script>
    import "vue3-carousel/dist/carousel.css";
    import { Carousel, Slide, Pagination } from "vue3-carousel";
    import constantes from "../common/constantes";
    const { APIs } = constantes;

    export default {
        name: "NoticiasCarousel",
        components: {
            Carousel,
            Slide,
            Pagination,
        },
        data() {
            return {
                Noticias: [],
            };
        },
        watch: {
            Noticias: function (newNotices) {
                this.$emit("changeVisibleStatus", newNotices.length > 0);
            },
        },
        computed: {
            authToken: function () {
                return this.$store.getters.getToken;
            },
        },
        mounted() {
            this.apiQuery(APIs.News, {
                method: "GET",
                mode: "cors",
                headers: {
                    Authorization: "Bearer " + this.authToken,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json())
                .then((result) => {
                    this.Noticias = result;
                  //  console.log(this.Noticias);
                })
                .catch((error) => console.log(error));
        },
    };
</script>

<style scoped>
    .caroussel {
        height: 150px;
    }

    .imgCarousel {
        max-height: 75px;
        max-width: 75px;
    }
</style>
