import { createStore } from "vuex";
import { getCookie, removeCookie, setCookie } from "./cookies";

export const store = createStore({
    state() {
        return {
            user:
                getCookie("authorizationToken") != ""
                    ? JSON.parse(Buffer.from(getCookie("authorizationToken").split(".")[1], "base64").toString("utf8")).sub
                    : "",
            authorizationToken: getCookie("authorizationToken"),
            refreshToken: getCookie("refreshToken"),
            mustChangePassword: getCookie("mustChangePassword"),
            btrlList: getCookie("btrlList") != "" ? JSON.parse(getCookie("btrlList")) : "",
            userData: getCookie("userData") != "" ? JSON.parse(atob(getCookie("userData"))) : {},
            actualMenu: "user",
            menuVisible: false,
            responsableNotifications: null,
            isSpecialCenter: getCookie("isSpecialCenter"),
        };
    },
    mutations: {
        setToken(state, token) {
            state.authorizationToken = token;
            state.user = JSON.parse(Buffer.from(token.split(".")[1], "base64").toString("utf8")).sub;
            setCookie("authorizationToken", token, 0.5);
        },
        setPasswordChange(state, mustChange) {
            state.mustChangePassword = mustChange;
            setCookie("mustChangePassword", mustChange ? "yes" : "", 0.5);
        },
        setVisibleMenu(state, menu) {
            state.actualMenu = menu;
        },
        setSapCode(state, sapCode) {
            setCookie("sapCode", JSON.stringify(sapCode), 0.5);
        },
        toggleMenuVisibility(state) {
            state.menuVisible = !state.menuVisible;
        },
        setMenuVisibility(state, visibility) {
            state.menuVisible = visibility;
        },
        setResponsableNotifications(state, amount) {
            if (amount <= 0) {
                state.responsableNotifications = null;
            } else {
                state.responsableNotifications = amount;
            }
        },
        setBtrlList(state, btrlList) {
            state.btrlList = btrlList;
            setCookie("btrlList", JSON.stringify(btrlList), 0.5);
        },
        setUserData(state, userData) {
            state.userData = userData;
            setCookie("userData", btoa(JSON.stringify(userData)), 0.5);
        },
        setRefreshToken(state, token) {
            state.refreshToken = token;
            setCookie("refreshToken", token, 0.5);
        },
        setIsSpecialCenter(state, isSpecialCenter){
            state.isSpecialCenter = isSpecialCenter;
            setCookie("isSpecialCenter", isSpecialCenter, 0.5); 
        },
        logout() {
            removeCookie("authorizationToken");
            removeCookie("refreshToken");
            removeCookie("mustChangePassword");
            removeCookie("btrlList");
            removeCookie("userData");
            removeCookie("isSpecialCenter");

        },
    },
    getters: {
        getToken(state) {
            return state.authorizationToken;
        },
        mustChangePassword(state) {
            return state.mustChangePassword == "";
        },
        getVisibleMenu(state) {
            return state.actualMenu;
        },
        getUser(state) {
            return state.user;
        },
        getMenuVisibility(state) {
            return state.menuVisible;
        },
        getResponsableNotifications(state) {
            return state.responsableNotifications;
        },
        getBtrlList(state) {
            return state.btrlList;
        },
        getUserData(state) {
            return state.userData;
        },
        getRefreshToken(state) {
            return state.refreshToken;
        },
        getIsSpecialCenter(state){
            return state.isSpecialCenter;
        }
    },
});
