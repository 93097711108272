<template>
    <div class="error" v-if="error != null && error != ''"><i class="fa-solid fa-circle-exclamation"></i> {{ error }}</div>
    <div class="container">
        <div class="form">
            <form @submit.prevent="confirmBoxVisibility = true">
                <div class="formError" v-if="formError != null && formError != ''">
                    {{ formError }}
                </div>
                <div class="formSuccess" v-if="formSuccess != null && formSuccess != ''">
                    {{ formSuccess }}
                </div>
                <div class="form-group center">Restablecer contraseña</div>
                <div class="form-group">
                    <input type="text" required aria-required="true" aria-label="NIF" placeholder="NIF" aria-placeholder="NIF" v-model="user" />
                </div>
                <div class="form-group">
                    <input type="submit" value="Restablecer" aria-label="Restablecer contraseña" />
                    <ConfirmationBox
                        :visible="confirmBoxVisibility"
                        v-on:accept="
                            closeConfirmBox();
                            sendForm();
                        "
                        v-on:cancel="closeConfirmBox"
                        :message="' de que quieres restablecer tu contraseña'"
                    ></ConfirmationBox>
                    <router-link to="login" class="forgottentPassword">Volver al inicio de sesión</router-link>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
    import Constantes from "../common/constantes";
    import ConfirmationBox from "../components/ConfirmationBox.vue";
    const { APIs } = Constantes;

    export default {
        data() {
            return {
                user: "",
                error: "",
                formError: "",
                formSuccess: "",
                confirmBoxVisibility: false,
            };
        },
        components: {
            ConfirmationBox,
        },
        methods: {
            sendForm: function () {
                this.$isLoading(true);
                this.apiQuery(APIs.PasswordRecovery + this.user, {
                    method: "POST",
                    mode: "cors",
                })
                    .then((response) => {
                        if (response.status == 200) {
                            this.formError = "";
                            this.formSuccess = "Se ha enviado un email con las intrucciones";
                        } else {
                            this.formSuccess = "";
                            this.formError = "Ha ocurrido un error al tratar de enviar el email de recuperación";
                        }
                        this.$isLoading(false);
                    })
                    .catch(() => {
                        this.formSuccess = "";
                        this.formError = "Ha ocurrido un error inesperado, por favor, intentalo de nuevo más tarde";
                        this.$isLoading(false);
                    });
            },
            closeConfirmBox: function () {
                this.confirmBoxVisibility = false;
            },
            validateForm: function () {
                return this.user != "";
            },
        },
        computed: {},
    };
</script>

<style scoped>
    .form-group,
    input,
    button {
        font-size: 29px;
    }

    .error {
        z-index: 1;
        width: 100%;
        background-color: #c0392b;
        position: absolute;
        top: 0;
        left: 0;
    }

    .center {
        text-align: center;
    }

    .formError {
        color: white;
        background-color: red;
        font-weight: 550;
        font-size: 15px;
        width: calc(100% - 10px);
        padding: 5px;
        text-align: center;
        border-radius: 5px 5px 0 0;
        top: 0;
        left: 0;
    }

    .formSuccess {
        color: white;
        background-color: green;
        font-weight: 550;
        font-size: 15px;
        width: calc(100% - 10px);
        padding: 5px;
        text-align: center;
        border-radius: 5px 5px 0 0;
        top: 0;
        left: 0;
    }

    .container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        z-index: -1;
        background-image: url("../assets/background.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center top;
    }

    .mandatory {
        color: red;
        font-size: 15px;
        vertical-align: top;
    }

    label {
        display: none;
    }

    div.form {
        border: 0;
        background-color: #002e72;
        border-radius: 5px;
        color: white;
        width: 400px;
        height: 400px;
        margin: auto;
        top: 50%;
        -webkit-box-shadow: 2px 2px 7px 2px #000000;
        box-shadow: 2px 2px 7px 2px #000000;
    }

    .form-group {
        margin: 35px;
        text-align: center;
    }

    .form-group > input {
        width: calc(100% - 25px);
        border: 0;
        border-radius: 5px;
    }

    .form-group > input:focus {
        background-color: #cce0ff;
    }

    .form-group > input[type="submit"] {
        width: 100%;
        transition: ease 0.3s;
        border: 0;
        border-radius: 5px;
        background-color: #5590e7;
        height: 50px;
    }

    .form-group > input[type="submit"]:hover {
        background-color: #6ba4f8;
        cursor: pointer;
    }

    .form-group > input[type="submit"]:focus {
        background-color: #6ba4f8;
    }

    .form-group > * {
        display: block;
    }
</style>
