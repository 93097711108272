<template>
    <div class="error" v-if="error != null && error != ''"><i class="fa-solid fa-circle-exclamation"></i> {{ error }}</div>
    <div class="container">
        <div class="form">
            <div class="formError" v-if="formError != null && formError != ''">
                {{ formError }}
            </div>
            <div class="formSuccess" v-if="formSuccess != null && formSuccess != ''">
                {{ formSuccess }}
            </div>
            <form @submit.prevent="confirmBoxVisibility = true">
                <div class="form-group center">Cambio de contraseña</div>
                <div class="form-group">
                    <input
                        :type="showPassword ? 'text' : 'password'"
                        id="newPassword"
                        name="newPassword"
                        :disabled="passwordSended"
                        aria-label="Nueva contraseña"
                        required
                        aria-required="true"
                        placeholder="Nueva contraseña"
                        aria-placeholder="Nueva contraseña"
                        v-model="newPassword"
                    />
                </div>
                <div class="form-group" style="text-align: left">
                    <input
                        :type="showPassword ? 'text' : 'password'"
                        name="newPasswordConfirmation"
                        id="newPasswordConfirmation"
                        aria-label="Confirmar contraseña"
                        :disabled="passwordSended"
                        required
                        aria-required="true"
                        placeholder="Confirmación contraseña"
                        aria-placeholder="Confirmación contraseña"
                        v-model="newPasswordConfirmation"
                    />
                    <label class="container-checkbox" for="showPassword"
                        >Mostrar contraseña
                        <input
                            type="checkbox"
                            name="showPassword"
                            id="showPassword"
                            class="showPassword"
                            aria-label="Mostrar contraseña"
                            v-model="showPassword"
                        />
                        <span class="mark"></span>
                    </label>
                </div>
                <div class="form-group">
                    <input type="submit" aria-label="Cambiar contraseña" value="Cambiar contraseña" :disabled="passwordSended" />
                    <ConfirmationBox
                        :visible="confirmBoxVisibility"
                        v-on:accept="
                            closeConfirmBox();
                            sendForm();
                        "
                        v-on:cancel="closeConfirmBox"
                        :message="' de que quieres cambiar tu contraseña'"
                    ></ConfirmationBox>
                    <router-link to="/" class="forgottentPassword" v-if="showGoBack">Volver a la página principal</router-link>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
    const md5 = require("md5");
    import Constantes from "../common/constantes";
    import ConfirmationBox from "../components/ConfirmationBox.vue";
    const { APIs } = Constantes;

    export default {
        data() {
            return {
                newPassword: "",
                newPasswordConfirmation: "",
                error: "",
                formError: "",
                formSuccess: "",
                passwordSended: false,
                confirmBoxVisibility: false,
                showPassword: false,
            };
        },
        components: {
            ConfirmationBox,
        },
        methods: {
            sendForm: function () {
                if (this.validateForm()) {
                    if (this.validPassword()) {
                        const cryptedPassword = Buffer.from(md5(this.newPassword), "hex").toString("base64");
                        this.passwordSended = true;
                        this.apiQuery(APIs.ChangePassword, {
                            method: "POST",
                            headers: {
                                Authorization: "Bearer " + this.authToken,
                                Accept: "application/json",
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                user: this.user,
                                newPwd: cryptedPassword,
                            }),
                        })
                            .then((response) => {
                                if (response.ok) {
                                    this.formError = "";
                                    this.formSuccess = "Contraseña cambiada correctamente, volviendo a la página principal...";
                                    this.$store.commit("setPasswordChange", false);
                                    setTimeout(() => {
                                        this.$router.push("/");
                                    }, 2000);
                                } else if (response.state == 400) {
                                    this.formError = "Alguno de los campos es incorrecto";
                                } else if (response.state == 404) {
                                    this.formError = "Ha ocurrido un error, recarga la página e intentalo de nuevo";
                                } else if (response.state == 500) {
                                    this.formError = "Ha ocurrido un error inesperado, intentalo de nuevo más tarde";
                                }
                                this.passwordSended = false;
                            })
                            .catch(() => {
                                this.formError = "Ha ocurrido un error inesperado, intentalo de nuevo más tarde";
                                this.passwordSended = false;
                            });
                    }
                } else {
                    this.formError = "Las contraseñas deben coincidir y no pueden estar vacías";
                }
            },
            validateForm: function () {
                return this.newPassword == this.newPasswordConfirmation && this.newPassword != "";
            },
            validPassword: function () {
                if (this.newPassword.match("((?=.*\\d)(?=.*[a-zA-Z]).{6,})")) {
                    return true;
                } else if (this.newPassword.match("((?=.*[a-zA-Z]).{6,})")) {
                    this.formError = "Debes añadir almenos 1 número a la contraseña";
                } else if (this.newPassword.match("((?=.*\\d).{6,})")) {
                    this.formError = "Debes añadir almenos 1 letra a la contraseña";
                } else if (this.newPassword.length < 6) {
                    this.formError = "La contraseña debe contener almenos 6 carácteres";
                } else {
                    this.formError = "La contraseña debe contener 6 carácteres con almenos 1 número y 1 letra";
                }
                return false;
            },
            closeConfirmBox: function () {
                this.confirmBoxVisibility = false;
            },
        },
        computed: {
            authToken: function () {
                return this.$store.getters.getToken;
            },
            user: function () {
                return this.$store.getters.getUser;
            },
            showGoBack: function () {
                return !this.$store.state.mustChangePassword;
            },
        },
    };
</script>

<style scoped>
    .form-group,
    input,
    button {
        font-size: 29px;
    }

    .error {
        z-index: 1;
        width: 100%;
        background-color: #c0392b;
        position: absolute;
        top: 0;
        left: 0;
    }

    .center {
        text-align: center;
    }

    .formError {
        color: white;
        background-color: red;
        font-weight: 550;
        font-size: 15px;
        width: calc(100% - 10px);
        padding: 5px;
        text-align: center;
        border-radius: 5px 5px 0 0;
        top: 0;
        left: 0;
    }

    .formSuccess {
        color: white;
        background-color: green;
        font-weight: 550;
        font-size: 15px;
        width: calc(100% - 10px);
        padding: 5px;
        text-align: center;
        border-radius: 5px 5px 0 0;
        top: 0;
        left: 0;
    }

    .container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        z-index: -1;
        background-image: url("../assets/background.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center top;
    }

    .mandatory {
        color: red;
        font-size: 15px;
        vertical-align: top;
    }

    label {
        display: none;
    }

    div.form {
        border: 0;
        background-color: #002e72;
        border-radius: 5px;
        color: white;
        width: 440px;
        height: 440px;
        margin: auto;
        top: 50%;
        -webkit-box-shadow: 2px 2px 7px 2px #000000;
        box-shadow: 2px 2px 7px 2px #000000;
    }

    .form-group {
        margin: 35px;
        text-align: center;
    }

    .form-group > input {
        width: calc(100% - 25px);
        border: 0;
        border-radius: 5px;
    }

    .form-group > input:focus {
        background-color: #cce0ff;
    }

    .form-group > input[type="submit"] {
        width: 100%;
        transition: ease 0.3s;
        border: 0;
        border-radius: 5px;
        background-color: #5590e7;
        height: 50px;
    }

    .form-group > input[type="submit"]:hover {
        background-color: #6ba4f8;
        cursor: pointer;
    }

    .form-group > input[type="submit"]:focus {
        background-color: #6ba4f8;
    }

    .form-group > * {
        display: block;
    }

    .container-checkbox {
        display: inline;
        position: relative;
        padding-left: 35px;
        margin-bottom: 20px;
        cursor: pointer;
        font-size: 17px;
        vertical-align: middle;
    }

    /* Hide the default checkbox */
    .container-checkbox input {
        visibility: hidden;
        cursor: pointer;
    }

    /* Create a custom checkbox */
    .mark {
        position: absolute;
        top: 0;
        left: 0;
        height: 15px;
        width: 15px;
        background-color: white;
        border: 2px solid white;
        border-radius: 100%;
    }

    .container-checkbox:hover input ~ .mark {
        background-color: lightgray;
    }

    .container-checkbox input:checked ~ .mark {
        background-color: white;
    }

    /* Create the mark/indicator (hidden when not checked) */
    .mark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the mark when checked */
    .container-checkbox input:checked ~ .mark:after {
        display: block;
    }

    /* Style the mark/indicator */
    .container-checkbox .mark:after {
        left: 4px;
        top: 1px;
        width: 5px;
        height: 10px;
        border: solid blue;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
    }
</style>
