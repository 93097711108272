<template>
    <!--<div v-show="visible && !allLoaded" style="margin-top: 12%; text-align: center; vertical-align: middle">
        <i class="fas fa-spinner fa-spin fa-5x"></i>
        <br />
        Cargando...
    </div>-->
    <div id="DatosPersonalesComponent" class="DatosPersonales" v-show="visible && allLoaded">
        <h1 class="cabecera">Datos personales</h1>
        <hr />
        <div class="divTable">
            <div class="divTableBody">
                <div class="divTableRow">
                    <div class="divTableCell"><label>Nombre</label></div>
                    <div class="divTableCell">
                        <input type="text" v-model="DatosEmpleado.nombre" readonly aria-readonly="true" />
                    </div>
                </div>
                <div class="divTableRow">
                    <div class="divTableCell">
                        <label>Primer apellido</label>
                    </div>
                    <div class="divTableCell">
                        <input type="text" v-model="DatosEmpleado.primerApellido" readonly aria-readonly="true" />
                    </div>
                    <div class="divTableCell">
                        <label>Segundo apellido</label>
                    </div>
                    <div class="divTableCell">
                        <input type="text" v-model="DatosEmpleado.segundoApellido" readonly aria-readonly="true" />
                    </div>
                </div>
                <div class="divTableRow">
                    <div class="divTableCell">
                        <label>Nº identificación</label>
                    </div>
                    <div class="divTableCell">
                        <input type="text" v-model="DatosEmpleado.identificacion" readonly aria-readonly="true" />
                    </div>
                    <div class="divTableCell"><label>Código SAP</label></div>
                    <div class="divTableCell">
                        <input type="text" v-model="DatosEmpleado.codigoSap" readonly aria-readonly="true" />
                    </div>
                </div>
            </div>
        </div>

        <h1 class="cabecera">Domicilio</h1>
        <hr />
        <form id="formDireccion">
            <div class="divTable">
                <div class="divTableBody">
                    <div class="divTableRow">
                        <div class="divTableCell">
                            <label class="required"> Tipo de via</label>
                        </div>
                        <div class="divTableCell">
                            <input id="txtTipoVia" v-model="DatosDireccion.tipoVia" class="inputLarge" readonly
                                aria-readonly="true" />
                        </div>
                    </div>
                    <div class="divTableRow">
                        <div class="divTableCell">
                            <label class="required">Calle y numero</label>
                        </div>
                        <div class="divTableCell">
                            <input id="txtCalleNum" type="text" v-model="DatosDireccion.calleNumero" class="inputLarge"
                                required maxlength="60" readonly aria-readonly="true" />
                        </div>
                        <div class="divTableCell">
                            <input id="txtEdificio" type="text" v-model="DatosDireccion.edificio" maxlength="10"
                                readonly aria-readonly="true" />
                        </div>
                        <div class="divTableCell">
                            <input id="txtPuerta" type="text" v-model="DatosDireccion.puerta" class="inputExtraSmall"
                                maxlength="10" readonly aria-readonly="true" />
                        </div>
                    </div>
                    <div class="divTableRow">
                        <div class="divTableCell">
                            <label>Otros datos</label>
                        </div>
                        <div class="divTableCell">
                            <input id="txtOtrosDatos" type="text" v-model="DatosDireccion.otrosDatos" class="inputLarge"
                                maxlength="40" readonly aria-readonly="true" />
                        </div>
                    </div>
                    <div class="divTableRow">
                        <div class="divTableCell">
                            <label class="required">Población</label>
                        </div>
                        <div class="divTableCell">
                            <input id="txtPoblacion" type="text" v-model="DatosDireccion.poblacion" required
                                maxlength="40" readonly aria-readonly="true" />
                        </div>
                        <div class="divTableCell">
                            <label class="required">Código postal</label>
                        </div>
                        <div class="divTableCell">
                            <input id="txtCodPostal" type="text" v-model="DatosDireccion.codPostal" class="inputSmall"
                                required maxlength="10" readonly aria-readonly="true" />
                        </div>
                    </div>
                    <div class="divTableRow">
                        <div class="divTableCell">
                            <label class="required">País</label>
                        </div>
                        <div class="divTableCell">
                            <input id="txtPais" type="text" v-model="DatosDireccion.pais" required readonly
                                aria-readonly="true" />
                        </div>
                        <div class="divTableCell">
                            <label class="required">Provincia</label>
                        </div>
                        <div class="divTableCell">
                            <input id="txtrovincia" type="text" v-model="DatosDireccion.provincia" required
                                maxlength="3" readonly aria-readonly="true" />
                        </div>
                    </div>
                </div>
            </div>
            <!--  <input type="button" value="Guardar" class="modal-default-button"> -->
            <!-- <button type="submit" class="modal-default-button">Guardar</button>  -->
        </form>

        <h1 class="cabecera">Contacto</h1>
        <hr />
        <form id="formContacto">
            <div class="divTable">
                <div class="divTableRow">
                    <div class="divTableCell">
                        <label>Correo de representante</label>
                    </div>
                    <div class="divTableCell">
                        <input type="email" v-model="DatosContacto.correoElectronico" class="inputLarge" readonly
                            aria-readonly="true" />
                    </div>
                    <div class="divTableCell">
                        <label>Correo electrónico</label>
                    </div>
                    <div class="divTableCell">
                        <input type="email" v-model="DatosContacto.correoElectronico2" class="inputLarge" readonly
                            aria-readonly="true" />
                    </div>
                </div>
                <div class="divTableRow">
                    <div class="divTableCell"><label>Teléfono</label></div>
                    <div class="divTableCell">
                        <input type="number" v-model="DatosContacto.telf1" maxlength="20" readonly
                            aria-readonly="true" />
                    </div>
                    <div class="divTableCell">
                        <label>Teléfono móvil</label>
                    </div>
                    <div class="divTableCell">
                        <input type="number" v-model="DatosContacto.telf2" maxlength="20" readonly
                            aria-readonly="true" />
                    </div>
                </div>
                <div class="divTableRow">
                    <div class="divTableCell">
                        <label>Teléfono de empresa</label>
                    </div>
                    <div class="divTableCell">
                        <input type="number" v-model="DatosContacto.telf3" maxlength="20" readonly
                            aria-readonly="true" />
                    </div>
                    <div class="divTableCell">
                        <label>Teléfono de emergencia</label>
                    </div>
                    <div class="divTableCell">
                        <input type="number" v-model="DatosContacto.telf4" maxlength="20" readonly
                            aria-readonly="true" />
                    </div>
                </div>
            </div>
            <!-- <input type="button" value="Guardar" class="modal-default-button"> -->
        </form>
        <h1 class="cabecera">Familiares</h1>
        <hr />
        <table>
            <thead>
                <tr>
                    <th>Tipo familiar</th>
                    <th>Nombre</th>
                    <th>Apellido 1</th>
                    <th>Apellido 2</th>
                    <th>Fecha de nacimiento</th>
                    <th>Grado discapacidad</th>
                </tr>
            </thead>
            <tbody>
                <span v-if="DatosFamiliares.length == 0">No se han encontrado familiares</span>
                <tr v-for="element in DatosFamiliares" :key="element.id">
                    <td>{{ element.familyType }}</td>
                    <td>{{ element.name }}</td>
                    <td>{{ element.surname }}</td>
                    <td>{{ element.surname2 }}</td>
                    <td>{{ element.birthDate }}</td>
                    <td>{{ element.disability }}</td>
                </tr>
            </tbody>
        </table>
        <div style="height: 120px"></div>
    </div>
</template>

<script>
import { getCookie } from "../store/cookies";
import { APIs, menuIds } from "../common/constantes";

const menuId = menuIds.personalData;

export default {
    name: "DatosPersonales",
    props: ["visible"],
    components: {
        //CustomGrid: () => import("./CustomGrid.vue"),
        // ModalFamiliares
    },
    data() {
        return {
            allLoaded: false,
            loading: false,
            DatosDireccion: {
                itemId: "",
                tipoVia: "",
                calleNumero: "",
                edificio: "",
                puerta: "",
                otrosDatos: "",
                pais: "",
                poblacion: "",
                codPostal: "",
                provincia: "",
            },
            DatosContacto: {
                itemId: "",
                correoElectronico: "",
                correoElectronico2: "",
                telf1: "",
                telf2: "",
                telf3: "",
                telf4: "",
            },
            DatosEmpleado: {
                nombre: "",
                primerApellido: "",
                segundoApellido: "",
                identificacion: "",
                codigoSap: "",
                pais: "",
            },
            DatosFamiliares: [],
        };
    },
    methods: {
        getPersonalData: function () {
            return this.apiQuery(APIs.PersonalData +  this.user, {
                method: "GET",
                mode: "cors",
                headers: {
                    Authorization: "Bearer " + this.authToken,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json())
                .then((result) => {
                    const datosEmpleado = this.DatosEmpleado;
                    datosEmpleado.nombre = result.name;
                    datosEmpleado.primerApellido = result.surname;
                    datosEmpleado.segundoApellido = result.secondSurname;
                    datosEmpleado.identificacion = result.idNumber;
                })
                .catch((error) => console.log(error));
        },
        getAdressData: function () {
            return this.apiQuery(APIs.AddressData + this.user, {
                method: "GET",
                mode: "cors",
                headers: {
                    Authorization: "Bearer " + this.authToken,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json())
                .then((result) => {
                    const datosDireccion = this.DatosDireccion;
                    datosDireccion.tipoVia = result.streetType;
                    datosDireccion.calleNumero = result.address;
                    datosDireccion.edificio = result.building;
                    datosDireccion.puerta = result.door;
                    datosDireccion.otrosDatos = result.others;
                    datosDireccion.poblacion = result.locality;
                    datosDireccion.codPostal = result.postalCode;
                    datosDireccion.pais = result.country;
                    datosDireccion.provincia = result.province;
                })
                .catch((error) => console.log(error));
        },
        getContactData: function () {
            return this.apiQuery(APIs.ContactData + this.user, {
                method: "GET",
                mode: "cors",
                headers: {
                    Authorization: "Bearer " + this.authToken,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json())
                .then((result) => {
                    const datosContacto = this.DatosContacto;
                    datosContacto.correoElectronico = result.representativeEmail;
                    datosContacto.correoElectronico2 = result.email;
                    datosContacto.telf1 = result.telephone;
                    datosContacto.telf2 = result.mobile;
                    datosContacto.telf3 = result.businessPhone;
                    datosContacto.telf4 = result.emergencyPhone;
                })
                .catch((error) => console.log(error));
        },
        getFamilyData: function () {
            return this.apiQuery(APIs.FamilyData + this.user, {
                method: "GET",
                mode: "cors",
                headers: {
                    Authorization: "Bearer " + this.authToken,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then((response) => response.json())
            .then((result) => {

                        let id = 1;
                        result.forEach((element) => {
                            this.DatosFamiliares.push({
                                familyType: element.familyTypeDescription,
                                name: element.name,
                                surname: element.surname,
                                surname2: element.secondSurname,
                                birthDate: element.birthDateString,
                                disability: element.discapacityGrade,
                                id: id++,
                            });
                        })
                    
                })
                .catch((error) => console.log(error));
        },
        setSapCodes: function () {
            this.DatosEmpleado.codigoSap = "";
            let sapCodes = JSON.parse(getCookie("sapCode"));
            sapCodes.forEach((element) => {
                this.DatosEmpleado.codigoSap += element + ",";
            });
            this.DatosEmpleado.codigoSap = this.DatosEmpleado.codigoSap.substr(0, this.DatosEmpleado.codigoSap.length - 1);
        },
    },
    async mounted() {
        this.loading = true;
        this.setSapCodes();
        const personalData = this.getPersonalData();
        const addressData = this.getAdressData();
        const contactData = this.getContactData();
        const familyData = this.getFamilyData();

        Promise.all([personalData, addressData, contactData, familyData]).then(() => {
            this.allLoaded = true;
            this.loading = false;
            this.$emit("loaded");
        });
    },
    computed: {
        authToken: function () {
            return this.$store.getters.getToken;
        },
        user: function () {
            return this.$store.getters.getUser;
        },
        contentShowed: function () {
            return this.$store.getters.getVisibleMenu;
        },
    },
    watch: {
        loading: function (newLoading) {
            if (this.contentShowed == menuId) {
                this.$isLoading(newLoading);
                this.$emit("resized");
            }
        },
        contentShowed: function (newContent) {
            if (newContent == menuId) {
                this.$isLoading(this.loading);
            }
        },
    },
};
</script>
<style scoped>
table {
    border: 2px solid #002e72;
    border-radius: 3px;
    padding: 1px;
}

thead>tr>th {
    background-color: #002e72;
    color: rgb(204, 204, 204);
    padding: 7px;
    font-size: 14px;
}

tbody>tr>td {
    background-color: #ecf0f1;
}

.divTableCell {
    width: 20%;
}
</style>
