<template>
    <div class="modal-mask" v-if="visible">
        <div class="modal-wrapper">
            <div class="modal-container">
                <div class="modal-header">
                    <h3>Resultado</h3>
                </div>

                <div class="modal-body">
                    <table class="customGrid">
                        <thead>
                            <tr>
                                <th>Peticionario</th>
                                <th>Fecha de inicio</th>
                                <th>Fecha fin</th>
                                <th>Días</th>
                                <th>Resultado</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="petition in datos" :key="petition.id">
                                <td>{{ petition.userName }}</td>
                                <td>
                                    {{ moment(petition.dateStart).format("DD-MM-YYYY") }}
                                </td>
                                <td>
                                    {{ moment(petition.dateEnd).format("DD-MM-YYYY") }}
                                </td>
                                <td>{{ petition.days }}</td>
                                <td>{{ petition.RESULT }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="modal-footer">
                    <button class="modal-default-button" @click.prevent="exportCSVFile">Exportar</button>
                    <button class="modal-default-button" @click.prevent="close">Cerrar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _moment from "moment";

    export default {
        name: "ModalResultAprobacion",
        components: {},
        props: ["visible", "datos"],
        data() {
            return {
                columnsAprobaciones: [
                    { nombre: "Peticionario", id: "petitioner" },
                    { nombre: "Fecha de inicio", id: "dateStart" },
                    { nombre: "Fecha de fin", id: "dateEnd" },
                    { nombre: "Comentarios", id: "comments" },
                    { nombre: "D&iacute;as", id: "days" },
                    { nombre: "Resultados", id: "RESULT" },
                ],
            };
        },
        methods: {
            close() {
                this.$emit("visibleChild", !this.visible);
            },
            convertToCSV() {
                var objArray = JSON.stringify(this.datos);
                var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
                var str = "";
                this.columnsAprobaciones.forEach((column) => {
                    if (str != "") str += ",";
                    str += column.nombre;
                });
                str += "\r\n";

                for (var i = 0; i < array.length; i++) {
                    var line = "";
                    this.columnsAprobaciones.forEach((column) => {
                        if (line != "") line += ",";
                        if (column.id == "dateStart" || column.id == "dateEnd")
                            line += array[i][column.id] === null ? "" : this.moment(array[i][column.id]).format("DD-MM-YYYY");
                        else line += array[i][column.id] === null ? "" : array[i][column.id];
                    });

                    str += line + "\r\n";
                }

                return str;
            },
            exportCSVFile() {
                var csv = this.convertToCSV();

                var exportedFilenmae = "ResultadoAprobacion-" + this.moment().format("YYYY-MM-DD.hh.mm.ss") + ".csv";

                var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
                if (navigator.msSaveBlob) {
                    // IE 10+
                    navigator.msSaveBlob(blob, exportedFilenmae);
                } else {
                    var link = document.createElement("a");
                    if (link.download !== undefined) {
                        // feature detection
                        // Browsers that support HTML5 download attribute
                        var url = URL.createObjectURL(blob);
                        link.setAttribute("href", url);
                        link.setAttribute("download", exportedFilenmae);
                        link.style.visibility = "hidden";
                        link.dataset.interception = "off";
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
            },
            moment: function (...args) {
                return _moment(...args);
            },
        },
        computed: {
            authToken: function () {
                return this.$store.getters.getToken;
            },
            user: function () {
                return this.$store.getters.getUser;
            },
        },
    };
</script>
